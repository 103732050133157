import {IRouteState, ROUTE_NAMES} from "app/services/route/route.types";
import SetupContainer from "app/components/Setup/container";
import {hot} from "react-hot-loader";
import SittingContainer from "app/components/Sitting/container";
import VenuesContainer from "app/components/Venues/container";
import CustomerDetailsContainer from "app/components/CustomerDetails/container";
import SummaryContainer from "app/components/Summary/container";
import ThankYouContainer from "app/components/ThankYou/container";
import ErrorPageContainer from "app/components/ErrorPage/container";
import BookingCancelledContainer from "app/components/BookingCancelled/container";
import ManageBookingContainer from "app/components/ManageBooking/container";
import PaymentsContainer from "app/components/Payments/container";
import PaymentCompleteContainer from "app/components/PaymentComplete/container";
import {RouteService} from "app/services/route/route.service";
import {Transition} from "@uirouter/react";
import {
  CANCEL_INFO,
  CUSTOMER_DETAILS_INFO, ERROR_PAGE_INFO, MANAGE_BOOKING_INFO, PAYMENT_COMPLETE_INFO, PAYMENTS_INFO,
  SETUP_INFO,
  SITTING_INFO, SUMMARY_INFO, THANK_YOU_INFO, VENUES_INFO
} from './route.info';

const NS = 'RouteStates';

const SETUP: IRouteState = {
  ...SETUP_INFO,
  component: SetupContainer
}

const SITTING: IRouteState = {
  ...SITTING_INFO,
  component: hot(module)(SittingContainer)
}

const VENUES: IRouteState = {
  ...VENUES_INFO,
  component: hot(module)(VenuesContainer)
}

const CUSTOMER_DETAILS: IRouteState = {
  ...CUSTOMER_DETAILS_INFO,
  component: hot(module)(CustomerDetailsContainer)
}

const SUMMARY: IRouteState = {
  ...SUMMARY_INFO,
  component: hot(module)(SummaryContainer)
}

const THANK_YOU: IRouteState = {
  ...THANK_YOU_INFO,
  component: hot(module)(ThankYouContainer)
}

const ERROR_PAGE: IRouteState = {
  ...ERROR_PAGE_INFO,
  component: hot(module)(ErrorPageContainer)
}

const CANCEL: IRouteState = {
  ...CANCEL_INFO,
  component: hot(module)(BookingCancelledContainer)
}

const MANAGE_BOOKING: IRouteState = {
  ...MANAGE_BOOKING_INFO,
  component: hot(module)(ManageBookingContainer)
}

const PAYMENTS: IRouteState = {
  ...PAYMENTS_INFO,
  component: hot(module)(PaymentsContainer)
}

const PAYMENT_COMPLETE: IRouteState = {
  ...PAYMENT_COMPLETE_INFO,
  component: hot(module)(PaymentCompleteContainer)
}

export const states: IRouteState[] = [
  SETUP, SITTING, VENUES, CUSTOMER_DETAILS, SUMMARY,
  THANK_YOU, ERROR_PAGE, MANAGE_BOOKING, PAYMENTS, CANCEL, PAYMENT_COMPLETE
];

export default class RouteStates {

  static getCurrentRoute(): IRouteState {
    return RouteService.getRouter().stateService.current as IRouteState;
  }
}
