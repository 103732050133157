import {connect} from 'react-redux';
import Payments from './index';
import NarrowPayments from './narrow';
import {IRootState} from 'app/reducers';
import {AnyAction} from 'redux';
import {IDispatchFromProps, IStateFromProps} from './types';
import {ThunkDispatch} from 'redux-thunk';
import {
  paymentProviderType,
  IStripeInfo,
  IPrepareEwayData,
  IPrepareEwayFunctionData
} from 'app/services/client/client.types';
import {BookingActionsNS} from 'app/actions/booking/bookingActions';
import {sortBy} from 'lodash';
import {IGroupedTablesBoxItem} from '../GroupedTablesBox/types';
import {IVenue} from 'app/models';
import {IPaymentDetailsGenericData} from '../PaymentDetailsGeneric/types';
import {IApplyPromoCode} from 'app/actions/booking/interfaces';
import {IBookingPayment} from "app/services/booking/booking.types";
import Switch from './switch';

const NS = 'PaymentsContainer';

const mapStateToProps = ({widget}: IRootState): IStateFromProps => {
  const {activeVenue, booking, appSettings, hasPromoCodes} = widget;

  const isStripe: boolean = activeVenue && activeVenue.paymentSettings
    ? activeVenue.paymentSettings.paymentProvider === paymentProviderType.stripe
    : false;


  // maps props in customer to human friendly names
  const customerMap: IGroupedTablesBoxItem[] = [
    {order: 0, name: 'firstName', value: 'First Name'},
    {order: 1, name: 'lastName', value: 'Surname'},
    {order: 2, name: 'company', value: 'Company Name'},
    {order: 3, name: 'phone', value: 'Mobile'},
    {order: 4, name: 'email', value: 'Email'},
    {order: 10, name: 'notes', value: 'Requests'},
  ]

  const {customer, viewDate, viewTime, covers, payment, serviceName} = booking;

  // to fix an issue where the customer notes were displayed instead of booking notes.
  if (customer) {
    customer.notes = (booking && booking.isFromDiary === false ? booking.notes : '');
  }

  const customerDetailItems: IGroupedTablesBoxItem[] = customerMap.reduce((a: any, nameObj: IGroupedTablesBoxItem) => {
    const value: string = (customer as any)[nameObj.name];
    if (value) {
      a.push({
        name: nameObj.value,
        value,
        order: nameObj.order
      })
    }
    return a;
  }, []);

  return {
    theme: widget.theme,
    wrapperStyle: widget.wrapperStyle,
    stripeInstance: widget.stripe,
    isStripe,
    stripePublishableKey: isStripe ? (activeVenue.paymentSettings as IStripeInfo).publishableKey : null, // || 'pk_test_12345'
    venuePaymentSettings: activeVenue ? activeVenue.paymentSettings : null,
    currency: widget.activeVenue ? (widget.activeVenue as IVenue).currency : null,
    payment,
    footerNavShowCancel: appSettings.canDeleteBooking,
    booking: booking,
    customerDetails: {
      heading: 'Your Details',
      items: sortBy(customerDetailItems, 'order')
    },
    bookingDetails: {
      heading: 'Booking Details',
      items: [{
        name: 'Restaurant',
        value: activeVenue ? activeVenue.name : ''
      }, {
        name: 'Booking date',
        value: viewDate || ''
      }, {
        name: 'Time',
        value: viewTime || ''
      }, {
        name: 'Number of guests',
        value: covers.toString()
      }, {
        name: 'Service Name',
        value: serviceName
      }]
    },
    hasPromoCodes
  };
};


/**
 * Note this has interface that will need to be updated
 */
const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, AnyAction>): IDispatchFromProps => {
  return {
    handlePrepareEwayPayment: (): Promise<IPrepareEwayData | IPrepareEwayFunctionData> => {
      return dispatch(BookingActionsNS.prepareEwayPayment());
    },
    handleEwayPaymentSubmit: (formEl: HTMLFormElement): Promise<void> => {
      return dispatch(BookingActionsNS.submitEwayPayment(formEl));
    },
    handleConfirmState: () => {
      // just used for analytics
      dispatch(BookingActionsNS.handleConfirmState());
    },
    handleStripeLoaded: (stripe: stripe.Stripe) => {
      dispatch(BookingActionsNS.stripeLoaded(stripe));
    },
    handleStripePaymentSubmit: (
      card: stripe.elements.Element,
      token: stripe.Token,
      paymentDetails: IPaymentDetailsGenericData
    ): Promise<void> => {
      return dispatch(BookingActionsNS.submitStripePayment(card, token, paymentDetails));
    },
    handleCancelBooking: () => {
      dispatch(BookingActionsNS.deleteBooking());
    },
    handlePromotionCode: (promotionCode: string): Promise<IApplyPromoCode> => {
      return dispatch(BookingActionsNS.applyPromoCode(promotionCode));
    },
    getLatestBookingPaymentDetails: (booking): Promise<IBookingPayment> => {
      return dispatch(BookingActionsNS.getLatestBookingPaymentDetails(booking._id));
    },
    handlePriceMismatchError: (error: string): void => {
      dispatch(BookingActionsNS.showPaymentMismatchError(error));
    },
  }

};

const PaymentsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Switch as any);

export default PaymentsContainer;
