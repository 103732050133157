import React, {Component, createRef, RefObject} from 'react';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import {withStyles} from '@material-ui/styles';
import {CircularProgress, Paper, Typography} from '@material-ui/core';
import style from './style.module.scss';
import {
  expandPanelType,
  IDispatchFromProps,
  IGAWExpansionPanelProps,
  IState,
  IStateFromProps,
  ITimePickerExpansionPanelProps,
  IVerificationExpansionPanelProps
} from './types';
import CoverInput from 'shared-components/cover-input/index';
import {coverInputVariant} from 'shared-components/cover-input/types';
import {IWidgetTheme} from "shared-types/WidgetTypes";

import ServiceSelector from 'shared-components/service-selector-radios/index';
import SectionSelector from '../SectionSelector';
import MiniToast from '../MiniToast';
import ServiceMessageContainer from '../ServiceMessage/container';
import MenuOptionsContainer from 'app/components/MenuOptions/container';
import {BookingService} from 'app/services/booking/booking.service';
import {renderIf} from 'app/services/utils/utils.service';
import classNames from 'classnames';
import TimePickerContainer from '../TimePicker/container';
import AlertPanel from '../AlertPanel';
import {getDerivedStateFromProps, getStateForExpansionPanelChange} from './helpers';
import {IframeResizerService} from "app/services/iframeResizer/iframeResizer.service";
import { VerificationPanel } from '../VerificationPanel';
import {NextAvailableBookingDatePanel} from "app/components/NextAvailableBookingDatePanel";
import {GAWTimeList} from "app/components/TimePicker/GAWTimeList";
import OrHRule from "app/components/OrHRule";
import ScheduleFailDialogContainer from "app/components/ScheduleFailDialog/container";
import {
  IScheduleService,
  ISectionOrder
} from "shared-types/index";
import { ISelectableTime } from "app/components/TimePicker/types";
import {DateUtilsService} from "app/services/dateUtils/dateUtils.service";
import {cloneDeep} from "lodash";

const ReactMarkdown = require('react-markdown/with-html');

const NS = 'SittingPanels';

const ExpansionPanel = withStyles({
  root: {
    '&.Mui-expanded': {
      margin: '12px 0',
    }
  },
})(MuiExpansionPanel);

const ExpansionPanelDetails = withStyles({
  root: {
    padding: '10px 24px 18px',
    display: 'block'
  },
})(MuiExpansionPanelDetails);


const ExpansionPanelSummary = withStyles({
  root: {
    '&.Mui-expanded': {
      minHeight: '40px'
    }
  },
  content: {
    margin: 0,
    '&.Mui-expanded': {
      margin: 0
    }
  }
})(MuiExpansionPanelSummary);



export default class SittingPanels extends Component<IStateFromProps & IDispatchFromProps, IState> {

  readonly readState = {
    coversInputOpen: true,
    coversInputOpenKeepOpen: false,
    servicesSelectorOpen: false,
    sectionsSelectorOpen: false,
    bookingOptionsSelectorOpen: false,
    timesSelectorOpen: false,
    gawSelectorOpen: false,
    coversCount: 0,
    displayServices: false,
    displayTimes: false,
    rangeMax: 0,
    rangeMaxChangeMessage: '',
    displayVerificationPanel: false,
    displayNextAvailableBookingTimePanel: false
  };

  private serviceAlertWrapRef: RefObject<HTMLDivElement> = createRef();
  private sectionAlertWrapRef: RefObject<HTMLDivElement> = createRef();

  constructor(props: IStateFromProps & IDispatchFromProps) {
    super(props);
    this.state = {
      ...this.readState,
    };
    this.handleExpansionPanelChange = this.handleExpansionPanelChange.bind(this);
  }

  static getDerivedStateFromProps(nextProps: IStateFromProps & IDispatchFromProps, currentState: IState) {
    return getDerivedStateFromProps(nextProps, currentState);
  }



  componentDidUpdate(prevProps: IStateFromProps & IDispatchFromProps, prevState: IState) {
    if (this.props.serviceAlertMessage && this.serviceAlertWrapRef && this.serviceAlertWrapRef.current) {
      this.serviceAlertWrapRef.current.scrollIntoView({behavior: "smooth"});
    }
    if (this.props.sectionAlertMessage && this.sectionAlertWrapRef && this.sectionAlertWrapRef.current) {
      this.sectionAlertWrapRef.current.scrollIntoView({behavior: "smooth"});
    }
  }


  handleExpansionPanelChange(type: expandPanelType, typeToLeaveOpen?: expandPanelType): void {
    const state = getStateForExpansionPanelChange(this.state, type, this.props.coversPrefill, typeToLeaveOpen, this.props.hasMoreThan1Section);
    if (state) {
      this.setState(state);
    }
  }

  /**
   * When verification is accepted,
   * 1. Hide the verification panel
   * 2. Show the service selector panel
   */
  onVerificationAcceptance = () => {

    // update redux store
    this.props.handleVerificationAcceptance();

    this.setState({
      servicesSelectorOpen: true,
      displayVerificationPanel: false
    });
  }

  render() {
    // props comes from redux 'application' state
    const {
      isPreviewMode, theme, wrapperStyle, scheduleLoading, getMaxBreachMessage,minPaxPerBookingMessage, scheduleMessage, isStandbyMode,
      filteredServices, activeService, filteredSections, activeSectionId, hasMoreThan1Section, maxPeoplePerBooking,minPaxPerBooking,
      showMenuOptions, coversPrefill, viewTime, coversAlertMessage, sectionAlertMessage, serviceAlertMessage,
      handleServiceChange, handleSectionChange, noTablesAvailable, allScheduleLoadStatus,
      allVenueTimes, handleVenueTimeChange, allVenueSchedule, enableGAW, allVenueApiCall, emptyTimesMessage,
      shouldShowStandbyButton, hasValidTimes, standbyExhausted, isVenueOpen, closedVenueService, enableNoTableMessage, selectedTimeForOtherVenues
    } = this.props;


    // 'state' is handling panels expanded state and text
    const {
      displayServices, coversInputOpen, servicesSelectorOpen, sectionsSelectorOpen, bookingOptionsSelectorOpen, timesSelectorOpen,
      coversInputOpenKeepOpen, rangeMaxChangeMessage, displayTimes, displayVerificationPanel, displayNextAvailableBookingTimePanel
    } = this.state;

    const activeSectionName: string = BookingService.getSectionName(filteredSections, activeSectionId);
    const isLandscape = !IframeResizerService.isStacked(wrapperStyle);

    const verificationExpansionPanelComponent: JSX.Element = <VerificationExpansionPanel
      onPanelExpansion={this.handleExpansionPanelChange}
      onVerificationAcceptance={this.onVerificationAcceptance}
      additionalBookingRequirements={this.props.additionalBookingRequirements} />

    const timePickerProps: ITimePickerExpansionPanelProps = {
      viewTime,
      isLandscape,
      isStandbyMode,
      hasMoreThan1Section,
      timesSelectorOpen,
      showDivider: hasValidTimes || isStandbyMode || (!hasValidTimes && shouldShowStandbyButton),
      handleExpansionPanelChange: this.handleExpansionPanelChange
    }

    const GAWProps: IGAWExpansionPanelProps = {
      allScheduleLoadStatus,
      allVenueSchedule,
      allVenueTimes,
      enableGAW,
      isEditMode: this.props.isEditMode,
      allVenueApiCall,
      theme,
      handleVenueTimeChange: handleVenueTimeChange,
      nextAvailableBookingTimeMessage: enableNoTableMessage ? scheduleMessage || emptyTimesMessage : null,
      shouldShowStandbyButton,
      activeService,
      standbyExhausted,
      isVenueOpen,
      closedVenueService,
      selectedTimeForOtherVenues
    }

    return (
      <div className={style.root}>

        <ExpansionPanel expanded={coversInputOpen || coversInputOpenKeepOpen} data-testid="covers-panel">
          <ExpansionPanelSummary onClick={() => this.handleExpansionPanelChange(expandPanelType.covers)}>
            <Typography className={classNames({
              [style.expanderHeading]: true,
              [style.expanderHeadingIsLandscape]: isLandscape
            })}>
              {coversPrefill === 0 ?
                'How many people are coming?'
                : `Booking for ${coversPrefill} ${coversPrefill === 1 ? 'person' : 'people'}`
              }
            </Typography>

            <MiniToast theme={theme} timeout={3000}
              messages={[rangeMaxChangeMessage]}
              handleMessageChanges={(messages: string[]) => {
                // hides the messages again after a few seconds (see 'timeout' prop)
                this.setState({
                  ...this.state,
                  rangeMaxChangeMessage: messages[0]
                })
              }}
            />

          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={style.expPnlDet}>
            <CoverInput
              theme={theme as IWidgetTheme}
              wrapperStyle={wrapperStyle}
              hasDelay={true}
              coversPrefill={coversPrefill}
              getMaxBreachMessage={getMaxBreachMessage}
              minPaxPerBookingMessage={minPaxPerBookingMessage}
              maxPeoplePerBooking={maxPeoplePerBooking}
              minPeoplePerBooking={minPaxPerBooking}
              variant={coverInputVariant.large}
              allowMaxBreach={true}
              handleChange={(value: number, isShowingMessage: boolean) => {
                this.handleExpansionPanelChange(expandPanelType.services, isShowingMessage ? expandPanelType.covers : null);
                this.props.handleCoversCountChange(value);
              }}
              handlePendingChange={() => this.props.handleCoversCountPending()}
              />
            {renderIf(coversAlertMessage, () => (
              <div className={style.coversAlert}>
                <AlertPanel wrapperStyle={wrapperStyle} message={coversAlertMessage} />
              </div>
            ))}
          </ExpansionPanelDetails>
        </ExpansionPanel>
        {/* Render only when creating a booking */}
        {renderIf(!this.props.isEditMode && displayVerificationPanel, () => verificationExpansionPanelComponent)}

        {renderIf(displayServices, () => (
          <ExpansionPanel expanded={servicesSelectorOpen}>
            <ExpansionPanelSummary onClick={() => this.handleExpansionPanelChange(expandPanelType.services)}>
              <Typography className={classNames({
                [style.expanderHeading]: true,
                [style.expanderHeadingIsLandscape]: isLandscape
              })}>
                {!activeService || scheduleLoading ?
                  'Which service are you booking for?'
                  : `For ${activeService.name}`
                }
              </Typography>
            </ExpansionPanelSummary>

            <ExpansionPanelDetails className={[style.expPnlDetServices, style.expPnlDet].join(' ')}>

              {renderIf(isPreviewMode, () => (
                <AlertPanel wrapperStyle={wrapperStyle} message="Not supported in preview mode" />
              ), () => renderIf(scheduleLoading, () => (
                // if loading
                  <div className={classNames({
                    [style.loaderWrap]: true,
                    [style.loaderWrapIsCentered]: !isLandscape
                  })}>
                    <CircularProgress size={20} />
                  </div>
                ), () => (
                  // if loaded
                    <>
                      <ServiceSelector
                        isStacked={!isLandscape}
                        scheduleMessage={scheduleMessage}
                        services={filteredServices}
                        activeServiceId={activeService ? activeService.id : null}
                        handleSelect={(id: string) => {
                          if (isPreviewMode) {
                            console.warn(NS, 'disabled for Preview Mode');
                          } else {
                            handleServiceChange(id)
                              .then((activeService) => {
                                const activeServiceHasMessage = activeService &&
                                  (
                                    activeService.description
                                    || BookingService.isPaymentDetailsVisible(coversPrefill, activeService.paymentDetails)
                                  );

                                this.handleExpansionPanelChange(
                                  this.props.hasAtLeast1Section ? expandPanelType.sections : expandPanelType.times,
                                  activeServiceHasMessage ? expandPanelType.services : null
                                );

                                if(activeService.minPaxPerBooking && coversPrefill < activeService.minPaxPerBooking ){
                                  this.handleExpansionPanelChange(expandPanelType.covers, activeServiceHasMessage ? expandPanelType.services : null)
                                  this.props.handleCoversCountChange(activeService.minPaxPerBooking)
                                }

                              });
                          }
                        }}
                      />
                      <ServiceMessageContainer isNabActive={displayNextAvailableBookingTimePanel} />
                      {renderIf(serviceAlertMessage, () => (
                        <div className={style.servicesAlert} ref={this.serviceAlertWrapRef}>
                          <AlertPanel wrapperStyle={wrapperStyle} message={serviceAlertMessage} />
                        </div>
                      ))}
                    </>
                  )
                )
              )}
            </ExpansionPanelDetails>
          </ExpansionPanel>
        ))}

        {/* if there is only 1 section, no need to select it */}
        {renderIf(hasMoreThan1Section, () => (
          <ExpansionPanel expanded={sectionsSelectorOpen}>
            <ExpansionPanelSummary onClick={() => this.handleExpansionPanelChange(expandPanelType.sections)}>
              <Typography className={classNames({
              [style.expanderHeading]: true,
              [style.expanderHeadingIsLandscape]: isLandscape
            })}>
                {activeSectionName ?
                `In ${activeSectionName}`
                : 'Which section would you like to be in?'
                }
              </Typography>
            </ExpansionPanelSummary>

            <ExpansionPanelDetails className={[style.expPnlDetSections, style.expPnlDet].join(' ')}>
              <SectionSelector
                wrapperStyle={wrapperStyle}
                theme={theme as IWidgetTheme}
                isStandbyMode={this.props.isStandbyMode}
                activeService={activeService ? activeService : null}
                sections={filteredSections}
                activeSectionId={activeSectionId}
                handleSelect={(id: string) => {
                  if (isPreviewMode) {
                    console.warn(NS, 'disabled for Preview Mode');
                  } else {
                    handleSectionChange(id);
                    // if (showMenuOptions) {
                    //   this.handleExpansionPanelChange(expandPanelType.bookingOptions);
                    // }
                    this.handleExpansionPanelChange(expandPanelType.times);
                  }
                }}
                />

              {renderIf(sectionAlertMessage, () => (
                <div className={style.sectionsAlert} ref={this.sectionAlertWrapRef}>
                  <AlertPanel wrapperStyle={wrapperStyle} message={sectionAlertMessage} />
                </div>
              ))}
            </ExpansionPanelDetails>
          </ExpansionPanel>
        ))}

        {/* NBI-951 removed `!isLandscape && ` so shows times for all view types */}
        {renderIf((displayTimes || displayNextAvailableBookingTimePanel) && !isPreviewMode, () => (
          displayNextAvailableBookingTimePanel ? <NextBookingAvailabilityStaticPanel {...GAWProps}  /> :
            <TimePickerExpansionPanel {...timePickerProps}  />
        ))}

        {renderIf(!noTablesAvailable && showMenuOptions && !isPreviewMode, () => (
          // <ExpansionPanel expanded={bookingOptionsSelectorOpen}>
          // Changing BO position and always keeping the BO open - NBI-4365
          <ExpansionPanel expanded={true}>
            <ExpansionPanelSummary onClick={() => this.handleExpansionPanelChange(expandPanelType.bookingOptions)}>
              <Typography className={classNames({
              [style.expanderHeading]: true,
              [style.expanderHeadingIsLandscape]: isLandscape
            })}>
                Additional options
              </Typography>
            </ExpansionPanelSummary>

            <ExpansionPanelDetails className={style.expPnlDet}>
              <MenuOptionsContainer />
            </ExpansionPanelDetails>
          </ExpansionPanel>
        ))}

        {/*We should still show GAW when NAB is switched off or the venue is closed*/}
        {renderIf((displayTimes || displayNextAvailableBookingTimePanel) && (enableGAW && !this.props.isEditMode) && !isPreviewMode, () => (
          <ExpansionPanel expanded={true}>
            <ExpansionPanelSummary
              onClick={() => this.handleExpansionPanelChange(expandPanelType.gaw)}>
              <Typography className={classNames({
                [style.expanderHeading]: true,
                [style.expanderHeadingIsLandscape]: isLandscape
              })}>
                Availability at our other venues
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <GAWTimeExpansionPanel {...GAWProps} />
            </ExpansionPanelDetails>
          </ExpansionPanel>
        ))}

        {/* Render only when editing a booking */}
        {renderIf(this.props.isEditMode && displayVerificationPanel, () => verificationExpansionPanelComponent)}


        <ScheduleFailDialogContainer />
      </div>
      )
  }
}

/** Expansion panel to render verification pop-up */
const VerificationExpansionPanel = (props: IVerificationExpansionPanelProps) => {
  return (
    <ExpansionPanel expanded={true}>
      <ExpansionPanelSummary onClick={() => props.onPanelExpansion(expandPanelType.verification)}>
        <Typography variant="h2" className={style.verificationHeader}>{props.additionalBookingRequirements?.verificationHeader}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <VerificationPanel onVerificationAcceptance={props.onVerificationAcceptance} verificationMessage={props.additionalBookingRequirements?.verificationMessage} />
        </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}

/** Expansion panel to render next available booking date and times */
const NextBookingAvailabilityStaticPanel = (props: IGAWExpansionPanelProps) => {
  const showGAW = props.enableGAW && !props.isEditMode;
  return (
    <Paper elevation={0} className={style.paperBox} data-testid="nabPanel">
      <div  className={style.paperBoxInner}>

        {props.nextAvailableBookingTimeMessage && (
          <>
            <Typography data-testid="nab-empty-times-msg" component="div">
              <ReactMarkdown className={style.addBottomMargin} source={props.nextAvailableBookingTimeMessage} escapeHtml={false} renderers={{ paragraph: 'span' }} />
            </Typography>
            <div className={style.ruleWrapNoText}><OrHRule showText={false} theme={props.theme} /></div>
          </>
        )}

        <NextAvailableBookingDatePanel theme={props.theme} showBottomHRule={showGAW} standbyExhausted={props.standbyExhausted} />
      </div>
    </Paper>
  );
}

/** Expansion panel to render next available booking date and times */
const GAWTimeExpansionPanel = (props: IGAWExpansionPanelProps) => {
  const showGAW = props.enableGAW && !props.isEditMode;
  return (
    <>
      {showGAW && (
        <GAWTimeList allVenueSchedule={props.allVenueSchedule} allScheduleLoadStatus={props.allScheduleLoadStatus}
                     allVenueTimes={props.allVenueTimes} allVenueApiCall={props.allVenueApiCall}
                     handleVenueTimeChange={props.handleVenueTimeChange}
                     theme={props.theme}
                     activeService={props.isVenueOpen ? props.activeService : props.closedVenueService}
                     selectedTimeForOtherVenues={props.selectedTimeForOtherVenues}
        />
      )}
    </>
  );
}

/** Expansion panel to render time selector */
const TimePickerExpansionPanel = (props: ITimePickerExpansionPanelProps) => {
  return (
    <ExpansionPanel expanded={props.timesSelectorOpen} data-testid="timePickerPanel">
      <ExpansionPanelSummary
        onClick={() => props.handleExpansionPanelChange(expandPanelType.times)}>
        <Typography className={classNames({
            [style.expanderHeading]: true,
            [style.expanderHeading]: true,
            [style.expanderHeadingIsLandscape]: props.isLandscape
          })}>
          {props.viewTime ? `At ${props.viewTime}` : (props.isStandbyMode ? 'Standby List' : 'Select a time')}
          {renderIf(props.isStandbyMode, () => (
            <span className={style.expanderSmallText} >
              &nbsp;&nbsp;(this is not a confirmed booking)
            </span>
          ))}
        </Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={[style.expPnlDetTimes, style.expPnlDet].join(' ')}>
        <TimePickerContainer handleTimeChange={() => {
          // props.handleExpansionPanelChange(
          //   props.hasMoreThan1Section
          //     ? expandPanelType.sections
          //     : expandPanelType.bookingOptions
          // );
          props.handleExpansionPanelChange(
            expandPanelType.bookingOptions
          );
        }} />
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}
