// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".loaderPanel--7g8kJ{min-height:100px;width:100%;display:flex;justify-content:center;align-items:center;margin-bottom:12px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loaderPanel": "loaderPanel--7g8kJ"
};
export default ___CSS_LOADER_EXPORT___;
