import React from 'react';
import style from './style.module.scss';
import { IStateFromProps, IDispatchFromProps } from './types';
import { Button, Typography } from '@material-ui/core';
import { UtilsService, renderIf } from 'app/services/utils/utils.service';
import { themeTypes } from 'app/models';
import classNames from 'classnames';

const NS = 'BookingCancelled';

export const getPhoneLink = (phone: string): {__html: string} => ({
  __html: UtilsService.getPhoneLinkHtml(phone)
});

export default function BookingCancelled({ theme, venuePhone, showBookAgainBtn, handleRebook }: IStateFromProps & IDispatchFromProps) {
  const isDark = theme.type === themeTypes.dark || theme.type === themeTypes.outlinedDark;
  return (
    <div className={classNames({
      [style.root]: true,
      [style.rootIsDark]: isDark
    })}>
      <Typography variant="h2" className={style.heading} >
        Booking Cancelled
      </Typography>

      {renderIf(venuePhone, () => (
        <Typography variant="body1" className={style.message} data-testid="body1">
          Thanks for cancelling your booking. If you would like to book for another time or have any questions, 
          please contact us on <span dangerouslySetInnerHTML={getPhoneLink(venuePhone)} />.
        </Typography>
      ))}

      {renderIf(showBookAgainBtn, () => (
        <Button 
          data-testid="book-btn"
          variant="contained" 
          color="primary" 
          onClick={handleRebook}>
          Make Another Booking
        </Button>
      ))}
    </div>
  )
}

