import { connect } from 'react-redux';
import ErrorPage from './index';
import { IRootState } from 'app/reducers';
import { AnyAction } from 'redux';
import { IDispatchFromProps, IStateFromProps } from './types';
import { ThunkDispatch } from 'redux-thunk';
import { bookingErrorMessageType, bookingErrorType } from 'app/services/error/error.types';
import { NavigationActionsNS } from 'app/actions/navigation/navigationActions';
import { ROUTE_NAMES } from 'app/services/route/route.types';
import {BookingActionsNS} from "app/actions/booking/bookingActions";

const NS = 'ErrorPageContainer';

const mapStateToProps = ({widget}: IRootState): IStateFromProps => {
  const {wrapperStyle, theme, bookingError} = widget;

  return {
    wrapperStyle, theme, bookingError
  };
};


/**
 * Note this has interface that will need to be updated
 */
const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, AnyAction>, ownProps: any): IDispatchFromProps => {
  return {
    handleReset: (messageType: bookingErrorMessageType, name: bookingErrorType) => {
      if (
        messageType === bookingErrorMessageType.paymentError ||
        // messageType === bookingErrorMessageType.cancellationFail ||
        name === bookingErrorType.paymentError // this covers quite a few different payment error types
      ) {
        dispatch(NavigationActionsNS.changedRouteByName(ROUTE_NAMES.PAYMENTS));
      } else if (name === bookingErrorType.timeExpired) {
        /**
         * Covers scenario where a booking time has expired by the time they get to saving the booking.
         * Sends them back to the sitting route, keeping their settings
         */
        dispatch(BookingActionsNS.returnToSittingAndClearTime());
      } else {
        dispatch(NavigationActionsNS.reload());
      }
    }
  }
};

const ErrorPageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ErrorPage as any);

export default ErrorPageContainer;
