import React from 'react';
import style from './style.module.scss';
import {Button, CircularProgress, createStyles, makeStyles} from '@material-ui/core';
import {footerNavTypes, IDispatchFromProps, IStateFromProps} from './types';
import {renderIf, UtilsService} from 'app/services/utils/utils.service';
import {blockNavType, IWidgetTheme} from 'app/models';
import classNames from 'classnames';
import {ThemeColorsService} from 'app/services/theme/themeColors.service';
import AlertPanel from '../AlertPanel';
import {IframeResizerService} from "app/services/iframeResizer/iframeResizer.service";

const NS = 'FooterNav';

export const useFooterStyles = makeStyles((theme: IWidgetTheme) => {
  const {secondary} = ThemeColorsService.getCommonThemeStyles(theme);
  return createStyles({
    nextButton: {
      '&.MuiButton-contained.Mui-disabled': {
        backgroundColor: `rgba(${ UtilsService.hexToRgb(secondary.main).join(',') }, 0.5)`,
        color: theme.palette.getContrastText(secondary.light)
      }
    },
    shadyButton: {
      '&.Mui-disabled': {
        boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)'
      }
    }
  });
});

export default function FooterNav({
  theme, handleNavPressed, handleDisabledNextPressed, nextType, showNextLoader,
  nextEnabled, prevEnabled, wrapperStyle, blockNav, venuePhone, handleCancelBooking
}: IStateFromProps & IDispatchFromProps) {

  const classes = useFooterStyles(theme);
  const isStacked = IframeResizerService.isStacked(wrapperStyle);

  let nextText = 'Next';
  switch(nextType) {
    case footerNavTypes.book:
      nextText = 'Book Now';
      break;

    case footerNavTypes.pay:
      nextText = 'Pay Now';
      break;

    case footerNavTypes.standby:
      nextText = 'Add to standby list';
      break;
  }
  return (
    <footer className={style.root} >

      {renderIf(blockNav === blockNavType.becauseOfPayment && venuePhone, () => (
        <div className={style.alertWrap}>
          <AlertPanel
            data-testid="paymentAlert"
            wrapperStyle={wrapperStyle}
            message={`Sorry, you are unable to add paid items to this booking online - please contact the venue on ${UtilsService.getPhoneLinkHtml(venuePhone)}
            if you require paid items to be added to your existing booking, or alternatively you can cancel this booking and create a new booking with your new requirements.`}
            buttonHandler={handleCancelBooking}
            buttonText="Cancel Booking"
          />
        </div>
      ))}

      <div className={classNames({
        [style.btnWrap]: true,
        [style.btnWrapIsStacked]: isStacked
      })}>
        <Button className={classNames({
          [classes.shadyButton]: true,
          [style.navBtn]: true,
          [style.navBtnIsChunky]: isStacked
        })}
          variant="contained" disabled={!prevEnabled}
          data-testid="prevBtn"
          onClick={() => handleNavPressed(footerNavTypes.prev)}>
          Back
        </Button>

        <div className={style.nextWrap} >
          {renderIf(showNextLoader || !!handleDisabledNextPressed, () => (
            <Button className={classNames({
              [style.disabledNext]: true,
              [style.navBtn]: true,
              [style.navBtnIsChunky]: isStacked
            })} variant="contained" disabled={nextEnabled} color="default" onClick={handleDisabledNextPressed}>
              {nextText}
            </Button>
          ))}

          {renderIf(showNextLoader, () => (
            <CircularProgress size={20} className={style.nextLoader} />
          ))}
          <Button className={classNames({
            [classes.nextButton]: true,
            [classes.shadyButton]: true,
            [style.navBtn]: true,
            [style.navBtnIsChunky]: isStacked
          })}
            variant="contained" disabled={!nextEnabled || showNextLoader} color="secondary"
            data-testid="nextBtn"
            onClick={() => handleNavPressed(nextType)}
          >
            <span className={classNames({
              // [style.nextText]: true,
              [style.nextTextIsLoading]: showNextLoader
            })}>
              {nextText}
            </span>
          </Button>

        </div>
      </div>
    </footer>
  )
}
