import moment from 'moment';
import { LocationService } from '../location/location.service';
import { Observable, Subscription, Subject } from 'rxjs';
import { ISessionTime } from './session.types';

const NS = 'SessionService';
const ONE_SEC = 1000;
const ONE_MIN = ONE_SEC * 60;

class SessionService {
  private remainingTime: number;
  private orderStartTime: moment.Moment;
  private interval: any;
  private isActive: boolean;
  private viewTime: string;

  private timeoutMins = 10 * ONE_MIN;
  private activeTimeoutMS = ONE_MIN * 3;

  private session$: Subject<ISessionTime>;

  // can be used for unit tests
  setCustomTimeoutMins(val: number): void {
    this.timeoutMins = val;
  }

  // can be used for unit tests
  setActiveTimeoutMS(val: number): void {
    this.activeTimeoutMS = val;
  }

  startSession(): Observable<ISessionTime> {

    // return the same observable if already created
    if (this.session$) {
      return this.session$;
    }

    this.session$ = new Subject();
    this.remainingTime = this.getSessionTime();
    this.orderStartTime = moment().millisecond(0);

    this.isActive = false;

    this.interval = setInterval(() => {
      const endTime = this.orderStartTime.clone().add(this.timeoutMins, 'milliseconds'); // adds 10 mins

      // difference between end time and current time in ms
      this.remainingTime = endTime.diff(moment().millisecond(0));

      if (this.remainingTime < ONE_SEC) {
        this.session$.next({
          viewTime: null,
          isActive: false
        });
        this.stopSession();
        return;
      }

      if (this.remainingTime < this.activeTimeoutMS) {
        this.isActive = true;
      }

      // FYI: the mock menu can activate this via LocationService.forceDev
      if (LocationService.isDevEnvironment()) {
        this.isActive = true;
      }

      this.viewTime = this.getTime(this.remainingTime);

      this.session$.next({
        viewTime: this.viewTime,
        isActive: this.isActive
      });
    }, ONE_SEC);

    return this.session$;
  }

  stopSession(doNotifyApp = true): void {
    clearInterval(this.interval);
    this.interval = null;

    if (this.session$) {
      this.session$.complete();
      this.session$ = null;
    }

    this.remainingTime = this.getSessionTime();
    this.isActive = false;

    if (doNotifyApp) {
      // analytics.event('Timeout', 'Payment Timeout');
    }
  }

  getTime(ms: number): string {
    const minutes = Math.floor(ms / 1000 / 60);
    const seconds = (ms / 1000) % 60;
    return minutes + ':' + (seconds < 10 ? `0${seconds}` : seconds) + ' minutes';
  }

  private getSessionTime(): number {
    return this.timeoutMins;// * ONE_MIN;
  }
}


// creates a singleton instance to export
const instance = new SessionService();
export { instance as SessionService };
