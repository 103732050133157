import React, {Component} from 'react';
import style from './style.module.scss';
import { withStyles } from '@material-ui/styles';
import MuiTextField from '@material-ui/core/TextField';
import {Paper, FormControlLabel, Typography, Divider, FormControl} from '@material-ui/core';
import classNames from 'classnames';
import {wrapperStyleType} from 'app/models';
import {IStateFromProps, IDispatchFromProps} from './types';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import {withFormik, FormikProps} from 'formik';
import * as Yup from 'yup';
import {renderIf, UtilsService} from 'app/services/utils/utils.service';
import PhoneNumberService from "shared-services/phone-number-service/index";
import {phoneNumberTypes} from "shared-services/phone-number-service/phoneNumber.types";
import isEqual from 'react-fast-compare';
import FooterNavContainer from '../FooterNav/container';
import ColumnWrap2Container from '../ColumnWrap2/container';
import AlertPanel from '../AlertPanel';
import {ROUTE_NAMES} from 'app/services/route/route.types';
import {LocationService} from 'app/services/location/location.service';
import {IframeResizerService} from "app/services/iframeResizer/iframeResizer.service";
import { externalLinkType } from 'shared-components/external-link/types';
import ExternalLinkContainer from '../ExternalLink/container';
import FlagPhonePickerReact from "shared-components/flag-phone-picker-react";

const NS = 'CustomerDetailsForm';

const TextField = withStyles({
  root: {
    '& > .MuiInputLabel-shrink': {
      transform: 'translate(0, 5px) scale(0.75)'
    },
    '& .MuiInputBase-input': {
      padding: '5px 0 6px',
      marginTop: '4px'
    },
    '& .MuiFormHelperText-root': {
      fontSize: '1.2rem'
    },
    '& label': {
      zIndex: 10
    },
    '& label + .MuiInput-formControl': {
      marginTop: '12px'
    }
  }
})(MuiTextField);

const TagLabel = withStyles({
  root: {
    '& .MuiCheckbox-root': {
      padding: '5px'
    }
  }
})(FormControlLabel);

interface IFormData {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  company?: string;
  notes?: string;
  country?: string;
  terms: boolean; // externally this is corresponds to 'subscribed' in ICustomer
  tags?: boolean[];
}

interface IFormTags {
  [key: string]: boolean;
}


// workaround for initial checked state on load (https://github.com/mui-org/material-ui/issues/16434)
const Checkbox = UtilsService.getMuiFormikCheckBox;

class InnerForm extends Component<FormikProps<IFormData & IFormTags> & IStateFromProps & IDispatchFromProps> {

  componentDidUpdate(prevProps: FormikProps<IFormData & IFormTags> & IStateFromProps & IDispatchFromProps) {
    if (!isEqual(prevProps.values, this.props.values) || !isEqual(prevProps.errors, this.props.errors)) {
      this.handleWholeFormChange();
    }
  }

  componentDidMount() {
    this.handleWholeFormChange();
  }

  /**
   * Handler for form as a whole
   */
  handleWholeFormChange = async () => {
    const {firstName, lastName, phone, email, company, notes, country, terms} = this.props.values;
    const {isValid, handleUpdate} = this.props;

    // const phoneNational: string = phone ? PhoneNumberService.formatNumber(phone, this.state.country, true, phoneNumberTypes.NATIONAL) : null;
    const phoneNational: string = phone ? PhoneNumberService.formatNumber(phone, country, true, phoneNumberTypes.NATIONAL) : null;

    const tags = this.props.tags.filter(t => (this.props.values as any)[t._id]);
    const phoneWithPrefix = PhoneNumberService.formatInterNationalPhoneNumber(phone, country);
    const phoneWithoutPrefix = phone;
    handleUpdate({
      firstName, lastName,
      phone: phoneWithPrefix,
      phoneNational, email, company, notes,
      subscribed: terms
    }, tags, terms && isValid, country, phoneWithoutPrefix);
  }

  render() {
    const {
      // custom props
      wrapperStyle,
      tags,
      country,
      loadLibPhoneNumber,
      theme,

      // formik props
      values,
      touched,
      errors,
      handleChange,
      handleBlur,
      getFieldProps, // convenience props for values, handleChange, handleBlur
      setFieldValue
    } = this.props;

    const isLandscape = !IframeResizerService.isStacked(wrapperStyle);
    const isStandard = wrapperStyle === wrapperStyleType.standard;
    const hasTags = tags && tags.length;
    const forceValidation: boolean = this.props.triedNext === ROUTE_NAMES.CUSTOMER_DETAILS;
    // if (forceValidation) {
      // console.log(NS, this.props)
      // this.props.setTouched();
      // this.props.validateForm();
    // }

    loadLibPhoneNumber();

    /**
     * iPhone's zoom into input text fields if they are less than 16px, so we device sniff here
     * and give Apple what it wants.
     */
    const useEnlargedFont: boolean = LocationService.detectPhone().isIPhone;

    return (
      <form noValidate className={style.root} data-testid="container">
        <ColumnWrap2Container>
          {/* left */}
          <Paper data-testid="left-sub-container" elevation={1} className={classNames({
              [style.paperBox]: true,
              [style.paperBox1]: true,
              [style.paperBox1IsLandscape]: isLandscape
            })}>
            <TextField
              className={classNames({
                [style.formText]: true,
                [style.formTextUseEnlargedFont]: useEnlargedFont
              })}
              id="firstName" name="firstName" label="First Name"
              required fullWidth
              {...getFieldProps('firstName')}
              error={forceValidation && !values.firstName || (touched.firstName && Boolean(errors.firstName))}
              helperText={touched.firstName ? errors.firstName : ''}
            />
            <TextField
              className={classNames({
                [style.formText]: true,
                [style.formTextUseEnlargedFont]: useEnlargedFont
              })}
              id="lastName" name="lastName" label="Last Name"
              required fullWidth
              {...getFieldProps('lastName')}
              error={forceValidation && !values.lastName || touched.lastName && Boolean(errors.lastName)}
              helperText={touched.lastName ? errors.lastName : ''}
            />
            {/* <TextField
              className={classNames({
                [style.formText]: true,
                [style.formTextUseEnlargedFont]: useEnlargedFont
              })}
              id="phone" name="phone" label={<span>Mobile <span className={style.smallLabel}>(including international code)</span></span>}
              required fullWidth
              value={values.phone}
              onChange={(evt) => {
                const international: string = country
                  ? PhoneNumberService.formatNumber(evt.target.value, country, true, phoneNumberTypes.INTERNATIONAL)
                  : '';
                handleChange({
                  ...evt,
                  target: {
                    name: 'phone',
                    // in theory, the country should always exist, but if it doesn't for some reason, we let them skip formating
                    value: (country && international) ? international : evt.target.value
                  }
                })
              }}
              onBlur={handleBlur}
              error={forceValidation && !values.phone || touched.phone && Boolean(errors.phone)}
              helperText={touched.phone ? errors.phone : ''}
            /> */}
            <FlagPhonePickerReact
              searchCustomer={() => {
                return;
              }}
              customers={[]}
              value={values.phone}
              handleBlur={handleBlur}
              handleChange={handleChange}
              country={country}
              handleWholeFormChangeForAutocomplete={() => {}}
              forceValidation={forceValidation}
              touched={touched.phone}
              errors={errors.phone}
              theme={theme}
          />
            <TextField
              className={classNames({
                [style.formText]: true,
                [style.formTextUseEnlargedFont]: useEnlargedFont
              })}
              id="email" name="email" label="Email"
              required fullWidth
              {...getFieldProps('email')}
              error={forceValidation && !values.email || touched.email && Boolean(errors.email)}
              helperText={touched.email ? errors.email : ''}
              inputProps={{autoCapitalize:"off"}}
            />
            <TextField
              className={classNames({
                [style.formText]: true,
                [style.formTextUseEnlargedFont]: useEnlargedFont
              })}
              id="company" name="company" label="Company Name"
              fullWidth
              {...getFieldProps('company')}
              error={touched.company && Boolean(errors.company)}
              helperText={touched.company ? errors.company : ''}
            />
          </Paper>

          {/* right */}
          <div className={classNames({
            [style.column2]: true
          })} data-testid="right-sub-container">
            <Paper elevation={1} className={classNames({
              [style.paperBox]: true,
              [style.paperBox2]: true
            })}>

              <TextField
                className={classNames({
                  [style.terms]: true,
                  [style.formText]: true,
                  [style.formTextUseEnlargedFont]: useEnlargedFont
                })}
                id="notes" name="notes" label="Notes" rows={hasTags ? 3 : 5}
                fullWidth multiline
                {...getFieldProps('notes')}
                error={touched.notes && Boolean(errors.notes)}
                helperText={touched.notes ? errors.notes : ''}
              />

              {renderIf(hasTags, () => (
                <div>
                  <div className={style.specialWrap}>
                    <Typography variant="subtitle1" >
                      Special Requirements
                    </Typography>

                    <div className={classNames({
                      [style.tagsWrap]: true,
                      [style.tagsWrapIsLandscape]: isLandscape
                    })}>
                      {tags.map(t => (
                        <TagLabel key={t._id}
                                  data-testid="tag-label"
                          className={classNames({
                            [style.tagControl]: true,
                            [style.tagControlIsStacked]: !isLandscape,
                            [style.tagControlIsStandard]: isStandard
                          })}
                          control={
                            <Checkbox
                              id={t._id} name={t._id}
                              className={style.checkbox}
                              {...getFieldProps(t._id)}
                              icon={<CheckBoxOutlineBlankIcon fontSize="default" />}
                              checkedIcon={<CheckBoxIcon fontSize="default" color="secondary" />}
                            />
                          }
                          label={t.name}
                        />
                      ))}
                    </div>
                  </div>
                  <Divider className={[style.divider, style.specialDivider2].join(' ')} />
                </div>
              ))}

              <FormControl required component="fieldset">
                <FormControlLabel
                  className={style.termsControl}
                  control={
                    <Checkbox
                      id="terms" name="terms" required
                      {...getFieldProps('terms')}
                      className={style.checkbox}
                      icon={<CheckBoxOutlineBlankIcon fontSize="default" />}
                      checkedIcon={<CheckBoxIcon fontSize="default" color="secondary" />}
                    />
                  }
                  label="I agree to the terms and conditions"
                />
                {renderIf(!this.props.values.terms && forceValidation, () => (
                  <div className={style.alert}>
                    <AlertPanel wrapperStyle={wrapperStyle} message="You need to agree to the terms and conditions before proceeding" />
                  </div>
                ))}
              </FormControl>
              <div className={style.tcLink}>
                <ExternalLinkContainer label="Terms and Conditions" type={externalLinkType.termsAndCond} useDullColor={true} />
              </div>
            </Paper>

            <FooterNavContainer />
          </div>
        </ColumnWrap2Container>
      </form>
    );
  }
}


/**
 * Formik wrapper
 */
export const CustomerDetailsForm = withFormik({
  validateOnMount: true,
  mapPropsToValues: (mapProps: IStateFromProps & IDispatchFromProps) => {
    const {
      customerDetails, tags, country, phoneWithoutPrefix
    } = mapProps;

    if (!customerDetails) {
      return {
        firstName: '',
        lastName: '',
        email: '',
        notes: '',
        phone: '',
        company: '',
        country: '',
        terms: false,
        tags: []
      };
    }

    const {firstName, lastName, email, company, notes, subscribed} = customerDetails;

    // Get country code from customerDetail's phone when it exists.
    let customerCountry: string;
    if(customerDetails.phone) {
        const parseNumber: any = PhoneNumberService.parseNumber(customerDetails.phone);
        if(parseNumber && typeof parseNumber !== 'string') {
          if(parseNumber.nationalNumber)
          customerCountry = parseNumber.country;
        }
    }

    // const customerCountry = customerDetails.country;

    // sets initial values in the form (must use empty strings, not null)
    const props = {
      firstName: firstName || '',
      lastName: lastName || '',
      email: email || '',
      notes: notes || '',
      // phone: PhoneNumberService.formatInterNationalPhoneNumber(phone, customerCountry || country) || '',
      phone: phoneWithoutPrefix || '',
      company: company || '',
      // country: customerCountry || country || '',
      country: customerCountry || country || '',
      terms: subscribed,
      ...tags.reduce((a: any, t) => {
        a[t._id] = t.selected || false;
        return a;
      }, {})
    };

    return props;
  },

  // Custom validation rules
  validate: (values: IFormData & IFormTags, props: IStateFromProps & IDispatchFromProps) => {
    const errors: any = {};

    // const country = props.customerDetails.country || props.country;
    const country = props.country;
    if (!PhoneNumberService.formatNumber(values.phone, country, true, phoneNumberTypes.INTERNATIONAL)) {
      errors.phone = 'This is not a valid phone number';
    }

    return errors;
  },

  // auto validation rules
  validationSchema: Yup.object({
    firstName: Yup.string()
      .required('You forgot to enter your first name'),
    lastName: Yup.string()
      .required('You forgot to enter your last name'),
    phone: Yup.string()
      .required('You forgot to enter your mobile number'),
    email: Yup.string()
      .email(`Sorry, that email address isn't valid`)
      .required('You forgot to enter your email address')
  }),

  // not using this, but it is mandatory property
  handleSubmit: (values, { setSubmitting }) => {}
})(InnerForm);
