import React, {useEffect, useState} from 'react';
import style from './style.module.scss';
import {IDispatchFromProps, IStateFromProps} from './types';
import FooterNavContainer from '../FooterNav/container';
import {Chip, Paper, Table, TableBody, TableCell, TableRow, Typography} from '@material-ui/core';
import ColumnWrap2Container from '../ColumnWrap2/container';
import {renderIf, UtilsService} from 'app/services/utils/utils.service';
import classNames from 'classnames';
import PaymentMessageContainer from '../PaymentMessage/container';
import {IframeResizerService} from "app/services/iframeResizer/iframeResizer.service";
import {wrapperStyleType} from "app/models";
import {IBookingMenuOptionExtrasUpdater} from "app/services/booking/booking.types";
import ChildMenuOptionOverlay from "shared-components/booking-options-section/ChildMenuOptionOverlay";
import { Tooltip, withStyles } from '@material-ui/core';
import {IWidgetTheme} from "shared-types/WidgetTypes";
import ScheduleFailDialogContainer from "app/components/ScheduleFailDialog/container";
const NS = 'Summary';


export default function Summary({wrapperStyle, details, tags, showPaymentDetails, upsellMenuOptions, theme, selectionData, handleDataForUpsell, hideUpsell}: IStateFromProps & IDispatchFromProps) {
  const hasTags = !!(tags && tags.length);
  const isStacked = IframeResizerService.isStacked(wrapperStyle);
  const isTakeAwayMode = false; // Maybe useful for future should takeaway mode be enabled on widget v2

  const [newSelectionData, setSelectionData] = useState<IBookingMenuOptionExtrasUpdater>(selectionData);

  return (
    <ColumnWrap2Container>
      {/* left */}
      <Paper elevation={1} className={[style.paperBox, style.paperBox1].join(' ')}>
        <Typography variant="h2" className={style.detailsTitle}>
          Confirm your details
        </Typography>
        <Table aria-label="summary" size="small" x-ms-format-detection="none"
               className={classNames({
                 [style.summaryTable]: true,
                 [style.summaryTableIsStacked]: isStacked,
                 [style.summaryTableIsNarrow]: wrapperStyle === wrapperStyleType.narrow,
                 [style.summaryTableIsStandard]: wrapperStyle === wrapperStyleType.standard
               })}>
          <TableBody>
            {details.map(item => (
              <TableRow key={item.order} data-testid="summary-detail-row">
                <TableCell>{item.name}</TableCell>
                <TableCell>{item.value}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>

      {/* right */}
      <div className={style.column2}>

        <Paper elevation={1} className={classNames({
          [style.paperBox]: true,
          [style.paperBox2]: true,
          [style.paperBox2IsLandscapeHasTags]: !isStacked && hasTags
        })}>
          {renderIf(hasTags, () => (
            <div>
              <div className={style.specialWrap}>
                <Typography variant="subtitle1">
                  Special Requirements
                </Typography>

                <div className={style.tagWrap}>
                  {/* Display tooltip when tag is aprox too long*/}
                  {tags.map(t => (
                    <div key={t._id} data-testid="summary-tag">
                      <Tooltip title={t.name.length >= 40 ? t.name : ""} enterTouchDelay={100}>
                        <Chip label={t.name} color="primary"/>
                      </Tooltip>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}

          {renderIf(showPaymentDetails, () => (
            <>
              <PaymentMessageContainer useActiveService={true}/>
              <Typography variant="body1" data-testid="summary-payment-confirm-message">
                Please confirm your {UtilsService.getOrderType(isTakeAwayMode)} details are correct before proceeding
                {isTakeAwayMode ? '.' : ' so that we can hold your table.'}
              </Typography>
            </>
          ), () => (
            <Typography variant="body1" data-testid="payment-message">
              By clicking the book now button, you are agreeing to the terms and conditions.
            </Typography>
          ))}
        </Paper>

        {renderIf(!hideUpsell && upsellMenuOptions.length > 0, () => (
          <ChildMenuOptionOverlay
            selectionData={newSelectionData}
            wrapperStyle={wrapperStyle}
            theme={theme as IWidgetTheme}
            currency={'AUD'}
            menuOptionsImplicit={upsellMenuOptions}
            menuOptionsExplicit={[]}
            isUpsell={true}
            handleClose={(type) => {
              setSelectionData(null);
              if (type === 'close') { // To call the payment type again if they skip the upsell
                handleDataForUpsell([]);
              }
            }}
            handleDone={(data) => {
              handleDataForUpsell(data.implicitChildMenuOptions);
            }}/>
        ))}
        <FooterNavContainer/>
        <ScheduleFailDialogContainer />
      </div>
    </ColumnWrap2Container>
  )
}

