import {Dispatch} from "redux";
import {IActionGen, loaderType, loadStatus} from "app/types/common.types";
import {IRootState} from "app/reducers";
import {SetupActionsNS} from "app/actions/setup/setupActions";
import {IStandbyData, IVenue} from "app/models";
import {ClientService} from "app/services/client/client.service";
import {first} from "rxjs/operators";
import {RouteService} from "app/services/route/route.service";
import {ROUTE_NAMES} from "app/services/route/route.types";
import {getSectionAny} from "app/components/SectionSelector/types";

const NS = 'StandbyActions';

let standbyTO: any;

export namespace StandbyActionsNS {

  // action type
  export enum Type {
    CHANGED_STANDBY_MODE = 'CHANGED_STANDBY_MODE',
    SELECTED_STANDBY_TIME = 'SELECTED_STANDBY_TIME',
    SET_IS_FLEXIBLE_TIME = 'SET_IS_FLEXIBLE_TIME',
    SAVE_TO_STANDBY_LIST_SUCCESS = 'SAVE_TO_STANDBY_LIST_SUCCESS',
    SAVE_TO_STANDBY_LIST_FAIL = 'SAVE_TO_STANDBY_LIST_FAIL',
  }

  // thunk action creators

  export const changedStandbyMode = (isStandbyMode: boolean) => (dispatch: Dispatch): Promise<void> => {

    return new Promise<void>((resolve) => {
      dispatch({type: Type.CHANGED_STANDBY_MODE, payload: isStandbyMode} as IActionGen<boolean>);

      if (standbyTO) {
        clearTimeout(standbyTO);
        standbyTO = null;
      }

      const delay = 1000;
      standbyTO = setTimeout(resolve, delay);
    });
  }

  export const selectedStandbyTime = (utcTime: string) => (dispatch: Dispatch): Promise<void> => {
    dispatch({type: Type.SELECTED_STANDBY_TIME, payload: utcTime} as IActionGen<string>);
    return Promise.resolve();
  }

  export const setIsFlexibleTime = (isFlexibleTime: boolean) => (dispatch: Dispatch): Promise<void> => {
    dispatch({type: Type.SET_IS_FLEXIBLE_TIME, payload: isFlexibleTime} as IActionGen<boolean>);
    return Promise.resolve();
  }

  export const saveToStandbyList = () => (dispatch: Dispatch, getState: () => any): void => {

    const {widget} = getState() as IRootState;
    const {appSettings, activeVenue} = widget;
    const activeSection = widget.activeSection || getSectionAny();

    dispatch({type: SetupActionsNS.Type.APP_LOAD_PROGRESS, payload: {
        appLoadMessage: 'Saving to standby list',
        appLoaderType: loaderType.hideContent // can't use overlay type because it would cause customer details page to reload
      }} as IActionGen<SetupActionsNS.IAppLoading>);

    const standbyData: IStandbyData = {
      ...widget.standbyData,
      serviceName: widget.activeService.name,
      serviceId: widget.activeService.id,
      sectionId: activeSection.id
    }

    const {
      covers, customer, sectionId, serviceId, tags, utcTime, isFlexibleTime
    } = standbyData;

    ClientService.saveToStandbyList({
      covers, customer, sectionId, serviceId, tags,
      desiredBookingTime: isFlexibleTime ? widget.activeService.times[widget.activeService.times.length-1].time : utcTime,
      isFlexibleTime
    }, (widget.activeVenue as IVenue).id)
      .pipe(first())
      .subscribe(response => {
        console.log(NS, 'response', response)
        RouteService.routeTo(ROUTE_NAMES.THANK_YOU, dispatch, appSettings, activeVenue)
          .then(() => {

            dispatch({type: Type.SAVE_TO_STANDBY_LIST_SUCCESS});
            dispatch({type: SetupActionsNS.Type.APP_LOAD_COMPLETE, payload: {
              completeLoadStatus: true,
              status: loadStatus.success
            }} as SetupActionsNS.IAppLoadComplete);
          })
      }, err => {
        console.warn(NS, 'saveToStandbyList error', err);
        RouteService.routeTo(ROUTE_NAMES.ERROR_PAGE, dispatch, widget.appSettings, widget.activeVenue)
          .then(() => {

            /**
             * @todo: failed reponses are far too generic on back end (standbyListService.TryValidateStandByRequest).
             * Needs more specific errors so we can give better feedback to user, such as 'max standby reached', etc.
             * Severity: medium
             */
            dispatch({type: Type.SAVE_TO_STANDBY_LIST_FAIL, payload: {
              heading: 'Sorry, failed to save to Standby List',
              messageType: null, // bookingErrorMessageType;
              name: null, //bookingErrorType;
              message: null, //string;
              buttonText: "Make another booking"
            }});

            dispatch({type: SetupActionsNS.Type.APP_LOAD_COMPLETE, payload: {
              completeLoadStatus: true,
              status: loadStatus.success
            }} as SetupActionsNS.IAppLoadComplete);
          });
      });
  }
}
