import {Dispatch} from "redux";
import {IApplyPromoCode, IPromoCodeFail} from "app/actions/booking/interfaces";
import {IRootState} from "app/reducers";
import {PaymentService} from "app/services/payment/payment.service";
import {IVenue} from "app/models";
import {first} from "rxjs/operators";
import {IHasPromoCodeResponseData, IPromoCodeResponse} from "app/services/payment/payment.types";
import {bookingErrorType, IErrorResponse} from "app/services/error/error.types";
import {RouteService} from "app/services/route/route.service";
import {ROUTE_NAMES} from "app/services/route/route.types";
import {IResponse} from "app/containers/App/types";
import {BookingActionsTypes} from "app/actions/booking/bookingActionsTypes";

const NS = 'PromoCodeActions';

export const applyPromoCode = (promotionCode: string, dispatch: Dispatch, getState: () => any): Promise<IApplyPromoCode> => {
  return new Promise(resolve => {
    const state = getState() as IRootState;
    const {appSettings, activeVenue, savedBooking} = state.widget;
    let {bookingId} = appSettings;
    // showProcessingOverlayLoader(dispatch);

    if (!bookingId && savedBooking) {
      bookingId = savedBooking.bookingId;
    }

    PaymentService.applyPromoCode(promotionCode, bookingId, (activeVenue as IVenue).id)
      .pipe(first())
      .subscribe((response: IPromoCodeResponse) => {

        dispatch({type: BookingActionsTypes.PROMOTION_CODE_VERIFIED, payload: {
            ...response.data,
            promotionCode
          }});
        // appLoadCompleteSuccess(dispatch);
        resolve({
          codeFailMsg: null,
          payment: {
            ...response.data,
            promotionCode
          }
        });
      }, ({response}: {response: IErrorResponse}) => {
        console.warn(NS, 'applyPromoCode failed', response)

        if (response.status === 404) {
          RouteService.routeTo(ROUTE_NAMES.ERROR_PAGE, dispatch, appSettings, activeVenue).then(() => {
            dispatch({type: BookingActionsTypes.PROMOTION_CODE_FAIL, payload: { errorType: bookingErrorType.bookingExpired }} as IPromoCodeFail);
            // appLoadCompleteSuccess(dispatch);
            resolve({
              codeFailMsg: null,
              payment: null
            });
          });
        } else {
          const msg = response.data ? response.data.message : null;
          // dispatch({type: Type.PROMOTION_CODE_FAIL, payload: { message: msg || 'Unknown error' }} as IPromoCodeFail);
          // appLoadCompleteSuccess(dispatch);
          resolve({
            codeFailMsg: msg || 'Unknown error',
            payment: null
          });
        }
      });
  });
}


export const verifyHasPromoCode = (dispatch: Dispatch, getState: () => any): Promise<void> => {
  return new Promise((resolve, reject) => {
    const state = getState() as IRootState;
    const {appSettings, activeVenue, savedBooking} = state.widget;
    let {bookingId} = appSettings;
    // showProcessingOverlayLoader(dispatch);

    if (!bookingId && savedBooking) {
      bookingId = savedBooking.bookingId;
    }

    // initially hides the promo code until it is verified that some exist to use
    dispatch({type: BookingActionsTypes.UPDATE_HAS_PROMOTION_CODE, payload: false});

    PaymentService.hasPromoCode(bookingId, (activeVenue as IVenue).id)
      .pipe(first())
      .subscribe(({data}: IResponse<IHasPromoCodeResponseData>) => {
        dispatch({type: BookingActionsTypes.UPDATE_HAS_PROMOTION_CODE, payload: data.showPromoCode});
        // appLoadCompleteSuccess(dispatch);
        resolve();
      }, ({response}: {response: IErrorResponse}) => {
        reject();
      });
  });
}
