
import React, { ReactElement } from 'react';
import style from './style.module.scss';
import classNames from 'classnames';
import { IStateFromProps, IDispatchFromProps } from './types';
import PaymentMessageContainer from '../PaymentMessage/container';
import PaymentCardLogos from '../PaymentCardLogos';
import { renderIf } from 'app/services/utils/utils.service';
import { Paper, Typography, Divider, Table, TableBody, TableRow, TableCell } from '@material-ui/core';
import { paymentProviderType, IEwayInfo } from 'app/services/client/client.types';
import { IntlService } from 'app/services/intl/intlService';
import { themeTypes } from 'app/models';
import MenuOptionSummary from 'app/components/MenuOptionSummary';
import { lookType } from 'app/components/MenuOptionSummary/types';
import AlertPanel from 'app/components/AlertPanel/index';

const NS = 'PaymentSummary';


export default function PaymentSummary({
  theme,
  wrapperStyle,
  venuePaymentProvider,
  venuePaymentSettings,
  menuOptions,
  email,
  currency,
  payment,
  warningMessage
}: IStateFromProps & IDispatchFromProps) {

  const isDark = theme.type === themeTypes.dark || theme.type === themeTypes.outlinedDark;

  const Price = ({val, isColored}: {val: number, isColored: boolean}): ReactElement => {
    return (
      <span className={isColored ? "secondary-text" : ''}>
        {IntlService.currencyValue(val, currency)}
      </span>
    )
  }
  
  return (
    <Paper className={style.root}>

      <Typography variant="h2" className={style.heading}>
        Payment
      </Typography>

      <Divider className={style.divider} />

      <div className={style.paymentMessage}>
        <PaymentMessageContainer />
      </div>


      <div className={classNames({
        [style.tableWrap]: true,
        [style.tableWrapIsDark]: isDark
      })}>
        {/* needs separate table because the email determines the width of the columns */}
        {renderIf(email, (
          <Table aria-label="customer details" size="medium" >
            <TableBody>
              <TableRow>
                <TableCell>Email:</TableCell>
                <TableCell align="right">{email}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        ))}
        
        <Table aria-label="summary" size="medium" >
          <TableBody>
            {renderIf(menuOptions, () => (
              <MenuOptionSummary theme={theme} currency={currency} menuOptions={menuOptions} look={lookType.paymentSummary} />
            ))}

            {renderIf(payment.discountAmount, (
              <TableRow>
                <TableCell>Required Payment ({currency}):</TableCell>
                <TableCell align="right">
                  <Price val={payment.price} isColored={true} />
                </TableCell>
              </TableRow>
            ))}
            {renderIf(payment.discountAmount, (
              <TableRow>
                <TableCell>Discount Amount ({currency}):</TableCell>
                <TableCell align="right">
                  <Price val={payment.discountAmount} isColored={true} />
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell>Total Amount ({currency}):</TableCell>
              <TableCell align="right">
                <Price val={payment.amountDue} isColored={true} />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>

      {renderIf(warningMessage, () => (
        <div className={style.alert}>
          <AlertPanel 
            wrapperStyle={wrapperStyle} 
            message={warningMessage}
            severity="warning"
            />
        </div>
      ))}


      {renderIf(venuePaymentProvider === paymentProviderType.eway, (
        <div className={style.cardLogos}>
          <PaymentCardLogos
            {...venuePaymentSettings as IEwayInfo}
            />
        </div>
      ))}
    </Paper>
  )
}

