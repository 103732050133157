// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".root--PYkon{padding:30px 15px;text-align:center;max-width:600px;margin:0 auto}.rootIsDark--xvqWe{color:#fff}.heading--P\\+8Pu{margin-bottom:12px}.message--dZWP0{margin-bottom:25px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "root--PYkon",
	"rootIsDark": "rootIsDark--xvqWe",
	"heading": "heading--P+8Pu",
	"message": "message--dZWP0"
};
export default ___CSS_LOADER_EXPORT___;
