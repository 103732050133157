import {Dispatch} from "redux";
import {IAction, IActionGen, loadStatus} from "app/types/common.types";
import {Moment} from "moment";
import {IChangedActiveServiceUpdate, IChangedBookingTime, ICoversUpdate} from "app/actions/booking/interfaces";
import {bookingNotFoundDispatch, loadScheduleForSavedBooking} from "app/actions/booking/helpers";
import {IVenue, IWidgetModel} from "app/models";
import {ISchedule} from "app/services/client/client.types";
import {sendEditedOnlineBookingAnalytics} from "app/actions/booking/analyticsHelpers";
import {RouteService} from "app/services/route/route.service";
import {ROUTE_NAMES} from "app/services/route/route.types";
import {IErrorResponse} from "app/services/error/error.types";
import {appLoadCompleteSuccess} from "app/actions/setup/helpers";
import {BookingActionsTypes} from "app/actions/booking/bookingActionsTypes";
import {IRootState} from "app/reducers";

const NS = 'EditBookingActions';


export namespace EditBookingActionsNS {

  export const editBooking = () => (dispatch: Dispatch, getState: () => any): Promise<void> => {
    const {widget} = getState() as IRootState;
    return _editBooking(dispatch, widget);
  }

  export const _editBooking = (dispatch: Dispatch, widget: IWidgetModel): Promise<void> => {
    return new Promise(resolve => {
      const {savedBooking, appSettings, activeVenue} = widget;
      const {covers, serviceId, utcTime, sectionId} = savedBooking;

      dispatch({type: BookingActionsTypes.CHANGED_BOOKING_DATE, payload: savedBooking.moment} as IActionGen<Moment>);
      dispatch({
        type: BookingActionsTypes.CHANGED_COVERS_COUNT,
        payload: {covers, clearSelectionMenuOptions: false}
      } as IActionGen<ICoversUpdate>);


      dispatch({type: BookingActionsTypes.SERVICE_SCHEDULE_LOADING});

      loadScheduleForSavedBooking(dispatch, activeVenue as IVenue, savedBooking)
        .then((schedule: ISchedule) => {
          dispatch({
            type: BookingActionsTypes.CHANGED_ACTIVE_SERVICE,
            payload: {serviceId, clearSelectionMenuOptions: false}
          } as IActionGen<IChangedActiveServiceUpdate>);
          dispatch({type: BookingActionsTypes.CHANGED_ACTIVE_SECTION, payload: sectionId} as IActionGen<string>);
          dispatch({type: BookingActionsTypes.CHANGED_BOOKING_TIME, payload: utcTime} as IChangedBookingTime);

          sendEditedOnlineBookingAnalytics(activeVenue);

          RouteService.routeTo(ROUTE_NAMES.SITTING, dispatch, appSettings, activeVenue).then(() => {
            resolve();
          });
        })
        .catch((response: IErrorResponse) => {
          console.log(NS, 'response', response)
          if (response?.status === 503) {
            dispatch({type: BookingActionsTypes.SERVICE_SCHEDULE_FAILED, payload: loadStatus.failedTemp} as IAction);
            appLoadCompleteSuccess(dispatch);
          } else {
            RouteService.routeTo(ROUTE_NAMES.MANAGE_BOOKING, dispatch, appSettings, activeVenue).then(() => {
              bookingNotFoundDispatch(dispatch);
              resolve();
            });
          }
        })
    });
  }
}
