import {IStandbyData, IWidgetModel} from "app/models";
import {IBooking} from "app/services/booking/booking.types";
import {DateUtilsService} from "app/services/dateUtils/dateUtils.service";
import {ISelectableTime} from "app/components/TimePicker/types";
import {BookingService} from "app/services/booking/booking.service";
import {IActionGen} from "app/types/common.types";
import { cloneDeep } from "lodash";
import {BookingActionsTypes} from "app/actions/booking/bookingActionsTypes";
import {SectionsAvailableService} from "app/services/sectionsAvailable/sectionsAvailable.service";

const NS = 'changedActiveSection';

export default {
  [BookingActionsTypes.CHANGED_ACTIVE_SECTION]: (state: IWidgetModel, action: IActionGen<string>): IWidgetModel => {
    const activeSection = state.activeService ? state.activeService.sections.find(s => s.id === action.payload) : null;
    const sectionId = activeSection ? activeSection.id : null;

    let booking: IBooking = {
      ...state.booking,
      sectionId
    }

    /**
     * Here we disable selected times that are no longer available with the new active section
     */
    const prevFilteredTimes = state.filteredTimes;
    const filteredTimes = state.activeService
      ? DateUtilsService.getFilteredTimesNonMutated(state.activeService.times, true, activeSection, booking.utcTime)
      : null;

    let timeError: string = null;
    if (prevFilteredTimes && filteredTimes) {
      const selectedTime = prevFilteredTimes.find(t => (t as ISelectableTime).isSelected);
      if (selectedTime && !filteredTimes.find(t => selectedTime.time === t.time && !t.isDisabled)) {
        selectedTime.isSelected = false;
        booking.viewTime = null;
        booking.utcTime = null;
        timeError = `Sorry, the time "${selectedTime.name}" was not available for section "${activeSection.name}".`;

        const hasMultipleService = state.schedule.services.length > 1;

        if (filteredTimes.filter(t => !t.isDisabled).length) {
          timeError += ` <br/>Please choose a different time${hasMultipleService ? ' or change to a different section': ''}.`;
        } else if (state.schedule.services.length > 1) {
          timeError += ' <br/>Please choose a different section.';
        }
      }
    }

    let standbyData: IStandbyData = null;
    let clearStandbyData = false;
    if (state.standbyData) {
      // if times are now available, then we don't need standby list, as they can just book normally
      clearStandbyData = filteredTimes && !filteredTimes.some(t => t.isDisabled);
      if (clearStandbyData) {
        // so we repopulate the booking time and select it
        booking = BookingService.getBookingFromStandbyData(state.standbyData, booking);
      } else {
        // if active service and section are available, we update the standbyData
        standbyData = BookingService.getStandbyDataFromBooking(booking, state.standbyData, filteredTimes);
      }
    }

    return {
      ...state,
      activeSection,
      manuallyChosenSectionId: sectionId,
      booking,
      filteredTimes: cloneDeep(filteredTimes),
      timeError,
      triedNext: null,
      blockNav: null,
      standbyData,
      standbyCleared: clearStandbyData
    }
  },

  [BookingActionsTypes.SET_ACTIVE_SECTION_FROM_ACTIVE_SERVICE]: (state: IWidgetModel): IWidgetModel => {

    const maxPeoplePerBookingDefault = state.activeVenue.widgetSettings.maxPeoplePerBooking;
    let filteredSections = SectionsAvailableService.getSections(state.activeService, state.booking.covers, maxPeoplePerBookingDefault);
    filteredSections = SectionsAvailableService.getFilteredSections(state.activeService, filteredSections);
    const availableSections = SectionsAvailableService.getAvailableSections(state.activeService.times, filteredSections);

    let activeSection = availableSections?.length && state.activeSection ? availableSections.find(s => s.id === state.activeSection.id) : null;
    activeSection = activeSection || availableSections[0] || null;
    const sectionId = activeSection?.id || null;

    const booking: IBooking = {
      ...state.booking,
      sectionId
    }

    return {
      ...state,
      booking,
      activeSection
    }
  }
}
