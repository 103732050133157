// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".heading--tWigm{margin-bottom:15px}.divider--N7pMS{margin-bottom:15px}.paperBox--kqDV4{display:flex;flex-direction:column;justify-content:space-between;padding:25px 25px 30px 25px}.group--na6s6{margin-top:35px}.group--na6s6:first-child{margin-top:0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"heading": "heading--tWigm",
	"divider": "divider--N7pMS",
	"paperBox": "paperBox--kqDV4",
	"group": "group--na6s6"
};
export default ___CSS_LOADER_EXPORT___;
