import {connect} from 'react-redux';
import SittingPanels from './index';
import {IRootState} from 'app/reducers';
import {AnyAction} from 'redux';
import {IDispatchFromProps, IStateFromProps} from './types';
import {ThunkDispatch} from 'redux-thunk';
import {BookingActionsNS} from 'app/actions/booking/bookingActions';
import {ISchedule} from 'app/services/client/client.types';
import {MessageService} from 'app/services/message/message.service';
import {IVenue, modeType} from 'app/models';
import {BookingService} from 'app/services/booking/booking.service';
import {IActionGen, loadStatus} from 'app/types/common.types';
import appValues from 'app/constants/appValues';
import {ValidateSittingType} from 'app/services/footer/footer.types';
import FooterService from 'app/services/footer/footer.service';
import {ROUTE_NAMES} from 'app/services/route/route.types';
import {NavigationActionsNS} from "app/actions/navigation/navigationActions";
import {isEmpty} from "lodash";
import {BookingActionsTypes} from "app/actions/booking/bookingActionsTypes";
import {
  IScheduleTime,
  IServicePaymentDetails,
  IScheduleService
} from "shared-types/index";
import {SectionsAvailableService} from "app/services/sectionsAvailable/sectionsAvailable.service";

const NS = 'SittingPanelsContainer';

const mapStateToProps = ({widget}: IRootState): IStateFromProps => {

  const {
    booking, activeService, activeVenue, filteredSections, appSettings, wrapperStyle,
    theme, activeSection, scheduleLoadStatus, triedNext, schedule, timeError, standbyData, filteredTimes, haveAcceptedVerification, savedBooking,
    allScheduleLoadStatus, allVenueTimes, allVenueSchedule, allVenueApiCall, standbyExhausted, hasMinimumPax, selectedTimeForOtherVenues, maxPaxOverride
  } = widget;

  const isVenueOpen: boolean = widget.schedule && widget.schedule.isVenueOpen;

  const enableGAW = activeVenue.groupAvailabilityActive && activeVenue.groupAvailabilitySubscriptionActive && (!isVenueOpen || BookingService.is25PercentSlotUnavailable(filteredTimes));

  const enableNextAvailableBooking = activeVenue ? activeVenue.widgetSettings.enableNextAvailableBooking : false;

  const additionalBookingRequirements = BookingService.getAdditionalBookingRequirements(widget);

  const canCustomersChooseSection = activeVenue ? activeVenue.widgetSettings.canCustomersChooseSection : false;

  const showBlockoutMessage = activeService ? activeService.isBlockoutPartiallyEnabled : false;
  const enableNoTableMessage = activeVenue ? activeVenue.widgetSettings.enableNoTableMessage || showBlockoutMessage : false;

  const maxPeoplePerBooking = schedule ? BookingService.getMaxPeoplePerBooking(activeVenue as IVenue, activeService, schedule) : maxPaxOverride;
  const minPaxPerBooking = activeService &&  activeService.minPaxPerBooking ?  activeService.minPaxPerBooking : 1;
  // this is the setting from an admin/account level (not including the booking options 'override')
  const maxPeoplePerBookingDefault = activeVenue ? activeVenue.widgetSettings.maxPeoplePerBooking : appValues.MAX_PEOPLE_PER_BOOKING;

  // const scheduleErrorMsg: string = schedule.blockoutMessage ? schedule.blockoutMessage : appMessages.getMessageText('dayClosedMessage', activeVenue);
  const filteredSchedule: ISchedule = isVenueOpen ? BookingService.removeOfflineServices(widget.schedule) : null;
  const covers: number = booking ? booking.covers : 0;

  // filters out services that all times have expired in
  const unfilteredServices = BookingService.getAvailableServicesFromSchedule(filteredSchedule, false);
  const filteredServices = BookingService.getAvailableServicesFromSchedule(filteredSchedule);
  const closedVenueService = !isVenueOpen && widget.schedule ? widget.schedule.services[0] : null;

  const paymentDetails: IServicePaymentDetails = activeService ? activeService.paymentDetails : null;
  const showMenuOptions: boolean = !standbyData && paymentDetails && BookingService.hasMenuOptions(activeService, covers, maxPeoplePerBookingDefault);

  const isPreviewMode: boolean = appSettings.mode === modeType.preview;


  // To check if any tables are available. If not then hide Additional Options Panel.
  const percentUnavailable: number = filteredTimes ? (filteredTimes.filter(t => t.isDisabled && !t.expired).length / filteredTimes.length) * 100 : 0;
  const noTablesAvailable: boolean = filteredTimes && filteredTimes.some(t => t.isDisabled && !t.expired) && percentUnavailable >= 100;

  let coversAlertMessage: string = null;
  let serviceAlertMessage: string = null;
  let sectionAlertMessage: string = null;
  let hasTimesAlertMessage = false;
  let hasMenuOptionAlert = false;
  if (triedNext === ROUTE_NAMES.SITTING) {
    const item = FooterService.validateSittingPage(booking, activeService, activeVenue, filteredSections, standbyData, widget);

    coversAlertMessage = FooterService.getAlertMessage(item, ValidateSittingType.Covers);
    if (!item.isValid && item.items.find(o => o.type === ValidateSittingType.MenuOptions)) {
      hasMenuOptionAlert = true;
    }

    sectionAlertMessage = FooterService.getAlertMessage(item, ValidateSittingType.Section);
    serviceAlertMessage = FooterService.getAlertMessage(item, ValidateSittingType.Service);
    hasTimesAlertMessage = !!FooterService.getAlertMessage(item, ValidateSittingType.Time);
  } else if (widget.isTimeViewShown && booking.sectionId) {
    const item = FooterService.validateSittingPage(booking, activeService, activeVenue, filteredSections, standbyData, widget);
    hasTimesAlertMessage = !!FooterService.getAlertMessage(item, ValidateSittingType.Time);
  }

  if (timeError) {
    hasTimesAlertMessage = true;
  }

  const blockoutMessageEnabled = activeService && activeService.isBlockoutPartiallyEnabled;
  const shouldShowStandbyButton = BookingService.isStandbyEligible(widget) && !blockoutMessageEnabled;
  const hasValidTimes = filteredTimes && filteredTimes.some(t => !t.isDisabled && !t.expired && !t.isBlocked);
  const isStandbyMode = !!standbyData;
  const isSelectedServiceAvailable = SectionsAvailableService.getIsSelectedServiceAvailable(activeService, isStandbyMode, filteredSections);
  const activeSectionId: string = isSelectedServiceAvailable && activeSection ? activeSection.id : '';

  return {
    theme,
    wrapperStyle,
    maxPeoplePerBooking,
    minPaxPerBooking,
    scheduleMessage: blockoutMessageEnabled && activeService.blockoutMessage ? "": BookingService.getScheduleMsg(schedule, unfilteredServices, activeVenue as IVenue, scheduleLoadStatus),
    filteredServices,
    scheduleLoading: scheduleLoadStatus === loadStatus.loading,
    activeService,
    filteredSections,
    hasMoreThan1Section: canCustomersChooseSection && isSelectedServiceAvailable && filteredSections.length > 1,
    hasAtLeast1Section: canCustomersChooseSection && isSelectedServiceAvailable && filteredSections.length >= 1,
    showMenuOptions,
    activeSectionId,
    getMaxBreachMessage: (maxPeoplePerBooking: number) => {
      // needs to be a function because the maximum can change depending on the service settings
      return MessageService.get('maximumPeopleMessage', {
        ...activeVenue,
        maxPeoplePerBooking
      });
    },
    minPaxPerBookingMessage: activeService?.minPaxPerBooking ? MessageService.get('minPaxPerBookingMessage', { minPaxPerBooking }) : null,
    canCustomersChooseSection,
    coversPrefill: booking.covers,
    isPreviewMode,
    viewTime: booking ? booking.viewTime : null,
    coversAlertMessage,
    sectionAlertMessage,
    serviceAlertMessage,
    hasTimesAlertMessage,
    hasMenuOptionAlert,
    isStandbyMode,
    noTablesAvailable,
    haveAcceptedVerification,
    isEditMode: !!savedBooking,
    additionalBookingRequirements,
    enableNextAvailableBooking,
    allScheduleLoadStatus,
    allVenueTimes,
    allVenueSchedule,
    enableGAW,
    allVenueApiCall,
    scheduleLoadStatus,
    emptyTimesMessage: blockoutMessageEnabled && activeService.blockoutMessage ? activeService.blockoutMessage: MessageService.get('noTablesAvailableMessage', activeVenue),
    shouldShowStandbyButton,
    hasValidTimes,
    standbyExhausted,
    isVenueOpen,
    closedVenueService,
    hasMinimumPax,
    enableNoTableMessage,
    selectedTimeForOtherVenues
  };
};


/**
 * Note this has interface that will need to be updated
 */
const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, AnyAction>): IDispatchFromProps => {
  return {
    handleCoversCountChange: async (coversCount: number) => {
      await dispatch(BookingActionsNS.changedCoversCount(coversCount));
      const allVenueSchedule: ISchedule[] = await dispatch(BookingActionsNS.getScheduleCallForAllVenues());
      if (!isEmpty(allVenueSchedule)) {
        dispatch(BookingActionsNS.getTimesForAllVenues(allVenueSchedule));
      }
    },
    handleServiceChange: async (id: string): Promise<IScheduleService> => {
      const activeService = await dispatch(BookingActionsNS.changedActiveService(id));

      // making GAW api call after getSchedule call
      const allVenueSchedule: ISchedule[] = await dispatch(BookingActionsNS.getScheduleCallForAllVenues());
      if (!isEmpty(allVenueSchedule)) {
        dispatch(BookingActionsNS.getTimesForAllVenues(allVenueSchedule));
      }

      dispatch({
        type: BookingActionsTypes.HAVE_MINIMUM_PAX,
        payload: !!(activeService && activeService.minPaxPerBooking)
      } as IActionGen<boolean>);

      return activeService;
    },
    handleSectionChange: (id: string) => {
      dispatch(BookingActionsNS.changedActiveSection(id));
    },
    handleCoversCountPending: () => {
      dispatch(NavigationActionsNS.coversPending());
    },
    handleVerificationAcceptance: () => {
      dispatch(BookingActionsNS.setVerificationAcceptance());
    },
    handleVenueTimeChange: (time: IScheduleTime) => {
      dispatch(BookingActionsNS.setTimeForOtherVenues(time));
    }
  }
};

const SittingPanelsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SittingPanels as any);

export default SittingPanelsContainer;
