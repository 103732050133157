// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".root--Way2v{padding:20px 23px;margin-bottom:15px}.cvcWrap--Ik7cW,.cardNameWrap--vc1yn,.expiryWrap--fTBTi,.cardNumberWrap--zUBZc{display:block;margin:0 0 20px 0;color:rgba(0,0,0,.75);font-size:12px;font-weight:400;padding-bottom:7px;border-bottom:1px solid rgba(0,0,0,.42)}.cvcWrap--Ik7cW{min-width:150px;position:relative}.cardNameWrap--vc1yn input{display:block;border:none;outline:none;width:100%;padding:2px 0 0 0;font-size:16px;font-weight:400;font-family:inherit;background-color:rgba(0,0,0,0);color:rgba(0,0,0,.87)}.cardNameWrap--vc1yn input::-webkit-input-placeholder{color:rgba(0,0,0,.4)}.cardNameWrap--vc1yn input::-moz-placeholder{color:rgba(0,0,0,.4)}.cardNameWrap--vc1yn input:-ms-input-placeholder{color:rgba(0,0,0,.4)}.cardNameWrap--vc1yn input::-ms-input-placeholder{color:rgba(0,0,0,.4)}.cardNameWrap--vc1yn input::placeholder{color:rgba(0,0,0,.4)}.cardNameWrap--vc1yn input:-webkit-autofill,.cardNameWrap--vc1yn input:-webkit-autofill:hover,.cardNameWrap--vc1yn input:-webkit-autofill:focus,.cardNameWrap--vc1yn input:-webkit-autofill:active{transition-delay:9999s}.cardNameWrapIsDark--XGm5G input{color:#fff}.cardNameWrapIsDark--XGm5G input::-webkit-input-placeholder{color:rgba(255,255,255,.4)}.cardNameWrapIsDark--XGm5G input::-moz-placeholder{color:rgba(255,255,255,.4)}.cardNameWrapIsDark--XGm5G input:-ms-input-placeholder{color:rgba(255,255,255,.4)}.cardNameWrapIsDark--XGm5G input::-ms-input-placeholder{color:rgba(255,255,255,.4)}.cardNameWrapIsDark--XGm5G input::placeholder{color:rgba(255,255,255,.4)}.fieldLabel--oBEOr{margin-bottom:4px;display:block}.fieldLabelIsDark--hvNCQ{color:#fff;border-bottom-color:#e0e0e0}.expiryCvcRow--9sVgs{display:flex;justify-content:space-between}.expiryCvcRowIsTightFit--d5a8w{display:block}.alert--ew9Lu{margin-bottom:5px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "root--Way2v",
	"cvcWrap": "cvcWrap--Ik7cW",
	"cardNameWrap": "cardNameWrap--vc1yn",
	"expiryWrap": "expiryWrap--fTBTi",
	"cardNumberWrap": "cardNumberWrap--zUBZc",
	"cardNameWrapIsDark": "cardNameWrapIsDark--XGm5G",
	"fieldLabel": "fieldLabel--oBEOr",
	"fieldLabelIsDark": "fieldLabelIsDark--hvNCQ",
	"expiryCvcRow": "expiryCvcRow--9sVgs",
	"expiryCvcRowIsTightFit": "expiryCvcRowIsTightFit--d5a8w",
	"alert": "alert--ew9Lu"
};
export default ___CSS_LOADER_EXPORT___;
