// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".root--2bMFp{display:flex;flex-direction:column}.alertWrap--1OJcO{margin-bottom:10px}.btnWrap--93Wog{display:flex;justify-content:space-between}.btnWrapIsStacked--VqL5r{padding-top:7px}.disabledNext--HE6Do{opacity:0;position:absolute;box-shadow:none;right:0}.nextWrap--jIihx{position:relative}.navBtn--rjpqS{line-height:1.3;padding:8px 15px;font-size:1.5rem}.navBtnIsChunky--EK0jY{font-size:1.6rem}.nextLoader--Qzxhe{position:absolute;z-index:10;left:50%;top:50%;margin-left:-10px;margin-top:-10px}.nextTextIsLoading--6-Sz2{opacity:.4}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "root--2bMFp",
	"alertWrap": "alertWrap--1OJcO",
	"btnWrap": "btnWrap--93Wog",
	"btnWrapIsStacked": "btnWrapIsStacked--VqL5r",
	"disabledNext": "disabledNext--HE6Do",
	"nextWrap": "nextWrap--jIihx",
	"navBtn": "navBtn--rjpqS",
	"navBtnIsChunky": "navBtnIsChunky--EK0jY",
	"nextLoader": "nextLoader--Qzxhe",
	"nextTextIsLoading": "nextTextIsLoading--6-Sz2"
};
export default ___CSS_LOADER_EXPORT___;
