import { connect } from 'react-redux';
import BookingCancelled from './index';
import { IRootState } from 'app/reducers';
import { AnyAction } from 'redux';
import { IDispatchFromProps, IStateFromProps } from './types';
import { ThunkDispatch } from 'redux-thunk';
import { NavigationActionsNS } from 'app/actions/navigation/navigationActions';

const NS = 'BookingCancelledContainer';

const mapStateToProps = ({widget}: IRootState): IStateFromProps => {


  return {
    // wrapperStyle: widget.wrapperStyle,
    theme: widget.theme,
    venuePhone: widget.activeVenue ? widget.activeVenue.phone : null,
    showBookAgainBtn: widget.bookingCancelled.showBookAgainBtn
  };
};


/**
 * Note this has interface that will need to be updated
 */
const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, AnyAction>): IDispatchFromProps => {
  return {
    handleRebook: () => {
      dispatch(NavigationActionsNS.reload(true));
    }
  }
};

const BookingCancelledContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(BookingCancelled as any);

export default BookingCancelledContainer;
