import React, { ReactElement } from 'react';
import style from './style.module.scss';
import { IStateFromProps, IDispatchFromProps } from './types';
import { Typography, Divider } from '@material-ui/core';
import { renderIf, UtilsService } from 'app/services/utils/utils.service';
import ExternalLinkContainer from '../ExternalLink/container';
import { externalLinkType } from 'shared-components/external-link/types';
import { noCase } from 'change-case';
import { IntlService } from 'app/services/intl/intlService';
import {servicePaymentType} from "shared-types/index";

const NS = 'PaymentMessage';

export default function PaymentMessage({
  paymentType, price, covers, currency, hasMenuOptions, bgColor, isPricePerPerson, dividePriceByCovers
}: IStateFromProps & IDispatchFromProps) {

  const Price = ({calcPrice}: {calcPrice: number}): ReactElement => {
    return (
      <span className="secondary-text">
        {IntlService.currencyValue(calcPrice, currency)}
      </span>
    )
  }

  const PRE_AUTH_TEXT = () => (
    <Typography variant="body1" data-testid="pre-auth-text">
      A credit card Booking Guarantee of&nbsp;
      <Price calcPrice={ hasMenuOptions || dividePriceByCovers || !isPricePerPerson? price : price * covers}/> is required to finalise
      your {UtilsService.getOrderType()}.
      Funds will be verified, but not charged to your card at this time.&nbsp;
      <ExternalLinkContainer label="View Booking Guarantee Policy." type={externalLinkType.preAuth} bgColor={bgColor}/>
    </Typography>
  );

  return (
    <div>
      {renderIf(!paymentType || paymentType === servicePaymentType.noPayment, () => (<></>), () => (
        renderIf(hasMenuOptions, () => (
          renderIf(paymentType === servicePaymentType.preAuth,
            // show preauth text
            PRE_AUTH_TEXT, () => (
            // else not preauth
            <Typography variant="body1">
              A { noCase(paymentType) } for each booking option is required.
            </Typography>
          ))
        ), () => (
          renderIf(paymentType === servicePaymentType.functionPayment, () => (
            <Typography variant="body1" data-testid="function-text">
              A payment of <Price calcPrice={price} /> is required to finalise your {UtilsService.getOrderType()}.
            </Typography>
          ), () => (
            renderIf(paymentType === servicePaymentType.preAuth, PRE_AUTH_TEXT, () => (
              (() => {
                const calcPrice: number = (isPricePerPerson && dividePriceByCovers) ? (price/covers) : price;
                return (
                  <Typography variant="body1">
                    A { noCase(paymentType) } of <Price calcPrice={calcPrice} /> {isPricePerPerson ? 'per person ' : ''}is required to finalise your {UtilsService.getOrderType()}.
                  </Typography>
                );
              })()
            ))
          ))
        ))
      ))}
    </div>
  )
}
