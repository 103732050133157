// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".root--Hoir8{display:flex;justify-content:center;margin-top:13px}.extrasBtnText--CeXJC{flex-grow:1;line-height:1.2;font-size:1.3rem}.table--dohKW{border:none !important}.table--dohKW td{border:none !important;padding:3px 0 3px 0;line-height:1.2;font-size:1.1rem}.table--dohKW td:last-child{text-align:right;padding-right:0}.table--dohKW tr:last-child td{border-bottom:none}.editIconBtn--lL\\+TK{padding:6px 4px}.editIconBtn--lL\\+TK svg{font-size:1.8rem}.addIcon--3tGjJ{font-size:1.8rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "root--Hoir8",
	"extrasBtnText": "extrasBtnText--CeXJC",
	"table": "table--dohKW",
	"editIconBtn": "editIconBtn--lL+TK",
	"addIcon": "addIcon--3tGjJ"
};
export default ___CSS_LOADER_EXPORT___;
