import React, { useEffect, useRef, useState } from 'react';
import style from './style.module.scss';
import {withStyles} from '@material-ui/core';
import classNames from 'classnames';
import ReactFlagsSelect from "react-flags-select";
import {CountriesWithCode} from  "shared-services/phone-number-service/country";
import PhoneIcon from '@material-ui/icons/Phone';
import MuiTextField from '@material-ui/core/TextField';
import {Autocomplete} from "@material-ui/lab";
import {ICustomer} from 'shared-types/index';
import UtilsService from "shared-services/utils-service/index";
import PhoneNumberService from 'shared-services/phone-number-service/index';
import {phoneNumberTypes} from "shared-services/phone-number-service/phoneNumber.types";
import { FlagPhonePickerStyle, IHandlers, IProps, IStringEvent } from './types';
import {
  themeTypes} from 'shared-types/index';

const TextField = withStyles({
  root: {
      '& > .MuiInputLabel-shrink': {
          transform: 'translate(0, 5px) scale(0.75)'
      },
      '& .MuiInputBase-input': {
          padding: '5px 0 6px',
          marginTop: '4px'
      },
      '& .MuiFormHelperText-root': {},
      '& label': {
          zIndex: 10,
          fontSize: '1.3rem'
      },
      '& label + .MuiInput-formControl': {
          marginTop: '12px'
      }
  }
})(MuiTextField);

const NS = 'FlagPhonePickerReact';

export type Countries = Record<string, string>;

// export default function FlagPhonePickerReact(params: IProps & IHandlers) {
export default function FlagPhonePickerReact(props: IProps & IHandlers) {

  const {
    isPhoneIcon,
    value,
    handleBlur,
    handleChange,
    country,
    searchCustomer,
    customers,
    handleWholeFormChangeForAutocomplete,
    forceValidation,
    touched,
    errors,

    uiStyle,
    theme,
  } = props;

  const [selectedCountry, setSelectedCountry] = useState(country ? country : '');
  const [nationalNumber, setNationalNumber] = useState('');
  const dropDownRef = useRef(null);

  const isDark = theme ? theme.type === themeTypes.dark || theme.type === themeTypes.outlinedDark : false;

  useEffect(() => {
    country !== selectedCountry && setSelectedCountry(country);
  }, [country])

  useEffect(() => {
    window.addEventListener('click', (e: any) => {
      if(e.path[0].id === 'rfs-btn') {
        const ulChild = dropDownRef.current?.children[0].children[1];
        if(ulChild) {
          const inputChild = ulChild.children[0].children[0];
          inputChild.focus();
        }
      }
    });
  }, [])



  const countries = CountriesWithCode.map((c) => c.code);
  const countryLabels: Countries = {};
  CountriesWithCode.forEach((c) => {
    countryLabels[c.code] = c.code + ' ' + c.dial_code;
  });

  const formatPhoneNumber = (phone: string, countryCode: string) => { // should be return country-dial-code-removed-number
    let label = countryLabels[countryCode]; // get country label ex: "AF +93"
    label = label.replace(countryCode + ' ', ''); // Remove country code from label ex: "+93"
    label = label + ' '; // Add space into label ex: "+93 "
    return phone ? phone.replace(label, '') : ''; // Remove label from phone number ex: "+61 2343 2343 2343" => "2343 2343 2343"
  }

  const formatAutoCompletePhoneNumber = (value: string) => {
    const parseNumber: any = PhoneNumberService.parseNumber(value);
    if(parseNumber && typeof parseNumber !== 'string') {
      if(parseNumber.nationalNumber) {
        setTimeout(() => {
          // setSelectedCountry(parseNumber.country);
          handleChange({
            ...{},
            target: {
                name: 'phone',
                value: parseNumber.nationalNumber
            }
          })
          country !== parseNumber.country && handleChange({
            ...{},
            target: {
                name: 'country',
                value: parseNumber.country
            }
          });
        }, 100);
        return parseNumber.nationalNumber;
      } else return value;
    }
    return value;
  }

  const checkCountryCode = (str: string): string => {
    if(str === '+1') {
      setSelectedCountry('US');
      handleChange({
        ...{},
        target: {
            name: 'country',
            value: 'US'
        }
      });
      return '';
    }
    const isExistedCode: {
      name: string;
      dial_code: string;
      code: string;
    } = PhoneNumberService.getCountryByDialCode(str);
    if(isExistedCode) {
      setSelectedCountry(isExistedCode.code);
      handleChange({
        ...{},
        target: {
            name: 'country',
            value: isExistedCode.code
        }
      })
      return '';
    } else {
      return str;
    }
  }

  return (
    <div className={classNames({
      [style.root]: true,
    })}>
      {
        isPhoneIcon &&
        <div>
          <PhoneIcon />
        </div>
      }
      <div
        ref={dropDownRef}
        className={classNames({
          [style.prefixContainer]: true,
          [style.prefixPaddingContainer]: uiStyle !== FlagPhonePickerStyle.diary &&
                                          (forceValidation && !value || (touched || value) && Boolean(errors)),
          [style.prefixDiaryPaddingContainer]: uiStyle === FlagPhonePickerStyle.diary &&
                                                Boolean(errors),
          [style.darkMode]: isDark,
        }) }
      >
        <ReactFlagsSelect
          searchable
          fullWidth
          placeholder={'Country Code'}
          countries={
            countries
          }
          customLabels={
            countryLabels
          }
          selected={selectedCountry}
          onSelect={(code) => {
            console.log(code)
            setSelectedCountry(code)

            handleChange({
              ...{},
              target: {
                  name: 'country',
                  value: code
              }
            })
          }}
          className={style.prefixFlagContainer}
          selectButtonClassName={classNames({
            [style.prefixButtonContainer]: true,
            [style.isDiaryPrefixButtonContainer]: uiStyle === FlagPhonePickerStyle.diary,
          })}
          selectedSize={12}
          optionsSize={12}
        />
      </div>
      <div className={classNames({
        [style.phoneNumberInputContainer]: true,
        [style.isDiary]: uiStyle === FlagPhonePickerStyle.diary,
      })}>
        <Autocomplete
          freeSolo
          disableClearable
          inputValue={value ? formatAutoCompletePhoneNumber(value): ''}
          onInputChange={(e: any) => searchCustomer(e)}
          onChange={(event: any, newValue: ICustomer) => {
              handleWholeFormChangeForAutocomplete(newValue)
          }}
          renderOption={(option: ICustomer) => (
              <React.Fragment>
                  <span>{UtilsService.getDisplayName(option, true)}</span>
              </React.Fragment>
          )}
          getOptionLabel={(option) => option.phone}
          options={customers as ICustomer[]}
          renderInput={(params) => (
              <TextField
                  data-testid="data-test-id-phone-flag-picker-value"
                  {...params}
                  label={
                    uiStyle !== FlagPhonePickerStyle.diary ?
                    <span>Mobile</span>
                    :
                    'Phone'
                  }
                  inputProps={{
                      ...params.inputProps,
                  }}
                  name="phone"
                  required={uiStyle !== FlagPhonePickerStyle.diary}
                  fullWidth
                  value={nationalNumber}
                  onChange={(evt) => {
                    let str: string = evt.target.value;

                    str = checkCountryCode(str);

                    const international: string = selectedCountry
                        ?
                          formatPhoneNumber(
                            PhoneNumberService.formatNumber(str, selectedCountry, true, phoneNumberTypes.INTERNATIONAL),
                            selectedCountry
                          )
                        : '';

                    setNationalNumber(
                      (selectedCountry && international) ? international : str
                    )

                    handleChange({
                        ...evt,
                        target: {
                            name: 'phone',
                            // in theory, the country should always exist, but if it doesn't for some reason, we let them skip formating
                            value: (selectedCountry && international) ? international : str
                        }
                    })
                  }}
                  onBlur={handleBlur}
                  error={
                    uiStyle !== FlagPhonePickerStyle.diary ?
                    forceValidation && !value || (touched || !!value) && Boolean(errors)
                    :
                    Boolean(errors)
                  }
                  helperText={
                    uiStyle !== FlagPhonePickerStyle.diary ?
                    touched || value ? errors : ''
                    :
                    touched ? errors : ''
                  }
              />
          )}
      />
      </div>

    </div>
  )
}

