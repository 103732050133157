// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tableWrap--UpSjY{margin:20px 0 5px}.buttonWrapStyles--bLBeF{display:flex;justify-content:space-between}.buttonWrapStylesSingleButton--mtmo1{display:flex;justify-content:flex-end}.bookingOptHeading--Mh-oV{font-size:15px;margin:25px 7px 6px}.errorMessage--71wGX{margin-top:25px}.paymentMessage--BsU0U{margin-top:10px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableWrap": "tableWrap--UpSjY",
	"buttonWrapStyles": "buttonWrapStyles--bLBeF",
	"buttonWrapStylesSingleButton": "buttonWrapStylesSingleButton--mtmo1",
	"bookingOptHeading": "bookingOptHeading--Mh-oV",
	"errorMessage": "errorMessage--71wGX",
	"paymentMessage": "paymentMessage--BsU0U"
};
export default ___CSS_LOADER_EXPORT___;
