import {expandPanelType, IDispatchFromProps, IState, IStateFromProps} from "./types";
import {BookingService} from '../../services/booking/booking.service';
import {loadStatus} from "app/types/common.types";
import {IScheduleService, ISectionOrder} from "shared-types/WidgetTypes";
import {cloneDeep} from "lodash";
import {ISelectableTime} from "app/components/TimePicker/types";
import {DateUtilsService} from "app/services/dateUtils/dateUtils.service";

/**
 * Moved a few functions into separate file as main index file was getting a bit too long
 */
const NS = 'SittingPanels (helpers)';

 /**
  * Some global props can affect state (such as max pax count and alert messages), so here we manage those exceptions.
  */
export function getDerivedStateFromProps(nextProps: IStateFromProps & IDispatchFromProps, currentState: IState) {
  const {
    hasMoreThan1Section, canCustomersChooseSection, maxPeoplePerBooking, coversPrefill,
    hasMenuOptionAlert, sectionAlertMessage, hasTimesAlertMessage, hasMinimumPax, activeService
  } = nextProps;

  const coversTooMuch: boolean = coversPrefill > maxPeoplePerBooking;

  const rangeMaxChangeMessage = currentState.rangeMax && currentState.rangeMax !== maxPeoplePerBooking ?
    `Max updated to ${maxPeoplePerBooking}` : '';

  const displaySections: boolean = hasMoreThan1Section && !nextProps.scheduleLoading;
  const displayTimes: boolean = nextProps.activeService && !nextProps.scheduleLoading;
  const coversCount: number = coversTooMuch ? maxPeoplePerBooking : coversPrefill;

   const activeServiceHasMessage = activeService &&
     (
       activeService.description
       || BookingService.isPaymentDetailsVisible(coversPrefill, activeService.paymentDetails)
     );

  const newState: IState = {
    ...currentState,
    displayServices: coversCount > 0 && shouldShowServicesPanel(nextProps),
    displayTimes,
    rangeMax: maxPeoplePerBooking,
    rangeMaxChangeMessage,
    coversCount,
    displayVerificationPanel: shouldShowVerificationPanel(coversCount, nextProps),
    displayNextAvailableBookingTimePanel: shouldShowNextAvailableBookingTimePanel(coversCount, nextProps)
  };

  // // expands services if it contains an alert
  // if (activeServiceHasMessage) {
  //   newState.servicesSelectorOpen = true;
  // }

  // expands sections if it contains an alert
  if (sectionAlertMessage) {
    newState.sectionsSelectorOpen = true;
  }

  // expands times if it contains an alert (only on stacked layout)
  if (hasTimesAlertMessage) {
    newState.timesSelectorOpen = true;
  }

  // expands menu options if it contains an alert
  if (hasMenuOptionAlert) {
    newState.bookingOptionsSelectorOpen = true;
  }

  if (coversTooMuch || hasMinimumPax) {
    newState.coversInputOpen = true;
  }

  /**
   * While `handleExpansionPanelChange` generally covers expand state, there are cases where the application state
   * can override it here, such as when a new date is selected while `servicesSelectorOpen` is true. But this should
   * not override when there is an alert message for services.
   */
  if (!activeServiceHasMessage) {
    if (canCustomersChooseSection && hasMoreThan1Section) {
      // this is for when the sections panel is allowed
      if (!displaySections) {
        newState.servicesSelectorOpen = !newState.coversInputOpen && !nextProps.activeService || nextProps.scheduleLoading;
      }
      if (displaySections && newState.sectionsSelectorOpen && !sectionAlertMessage) {
        newState.servicesSelectorOpen = false;
      }
    }
  }
  return newState;
}

/**
 * returns the new component state for when and expansionPanel is opened
 */
export function getStateForExpansionPanelChange(state: IState, type: expandPanelType, coversPrefill: number, typeToLeaveOpen?: expandPanelType, hasMoreThan1Section?: boolean): IState {

  switch (type) {
    case expandPanelType.covers:
      const servicesSelectorShouldOpen = typeToLeaveOpen ? typeToLeaveOpen === expandPanelType.services : false;
      return {
        ...state,
        coversInputOpen: true,
        coversInputOpenKeepOpen: false,
        servicesSelectorOpen: servicesSelectorShouldOpen,
        sectionsSelectorOpen: false,
        bookingOptionsSelectorOpen: false,
        timesSelectorOpen: false,
        gawSelectorOpen: false,
      };

    case expandPanelType.services:

      let coversInputOpenKeepOpen = typeToLeaveOpen ? typeToLeaveOpen === expandPanelType.covers : false;
      if (coversPrefill === 0) {
        coversInputOpenKeepOpen = true;
      }

      return {
        ...state,
        coversInputOpen: false,
        coversInputOpenKeepOpen,
        servicesSelectorOpen: true,
        sectionsSelectorOpen: false,
        bookingOptionsSelectorOpen: false,
        timesSelectorOpen: false,
        gawSelectorOpen: false,
      }

    case expandPanelType.times:

      const servicesSelectorOpenForTimes = !hasMoreThan1Section && typeToLeaveOpen ? typeToLeaveOpen === expandPanelType.services : false;
      return {
        ...state,
        coversInputOpen: false,
        coversInputOpenKeepOpen: false,
        servicesSelectorOpen: servicesSelectorOpenForTimes,
        sectionsSelectorOpen: false,
        bookingOptionsSelectorOpen: false,
        timesSelectorOpen: true,
        gawSelectorOpen: true,
      }

    case expandPanelType.gaw:

      return {
        ...state,
        coversInputOpen: false,
        coversInputOpenKeepOpen: false,
        servicesSelectorOpen: false,
        sectionsSelectorOpen: false,
        bookingOptionsSelectorOpen: false,
        timesSelectorOpen: true,
        gawSelectorOpen: true,
      }

    case expandPanelType.sections:

      const servicesSelectorOpen = typeToLeaveOpen ? typeToLeaveOpen === expandPanelType.services : false;
      return {
        ...state,
        coversInputOpen: false,
        coversInputOpenKeepOpen: false,
        servicesSelectorOpen,
        sectionsSelectorOpen: true,
        bookingOptionsSelectorOpen: false,
        timesSelectorOpen: false,
        gawSelectorOpen: false,
      }

    case expandPanelType.bookingOptions:
      return {
        ...state,
        coversInputOpen: false,
        coversInputOpenKeepOpen: false,
        servicesSelectorOpen: false,
        sectionsSelectorOpen: false,
        bookingOptionsSelectorOpen: true,
        timesSelectorOpen: false,
        gawSelectorOpen: false,
      }
  }

  return null;
}


/**
 * Show/Hide the services panel.
 */
export function shouldShowServicesPanel(props: IStateFromProps): boolean {

  // Booking form is opened in edit mode
  if (props.isEditMode) return true;

  // if schedule fails to load (except for 503, which is loadStatus.failedTemp), show the services panel so we can see the error message
  if (props.scheduleLoadStatus === loadStatus.failed) return true;

  /**
   * When creating a booking, if the required verification switch is ON then,
   * the verification pop-up must be accepted
   */
  if (props.additionalBookingRequirements?.requiresVerification && !props.haveAcceptedVerification) return false;

  /**
   * When the next available booking switch is ON then,
   * the venue must've atleast one service
   */
  if (props.enableNextAvailableBooking && props.filteredServices?.length === 0) return false;

  return true;
}

/**
 * Show/Hide the verification panel.
 */
export function shouldShowVerificationPanel(numberOfPax: number, props: IStateFromProps): boolean {

  if (props.scheduleLoadStatus !== loadStatus.success) return false;

  // Atleast 1 pax is selected
  if (numberOfPax < 1) return false;

  // Required verification switch is ON for the venue
  if (!props.additionalBookingRequirements?.requiresVerification) return false;

  // Verification pop-up is not accepted
  if (props.haveAcceptedVerification) return false;

  return true;
}

/**
 * Show/Hide the next available time panel.
 */
export function shouldShowNextAvailableBookingTimePanel(numberOfPax: number, props: IStateFromProps): boolean {

  // enableNextAvailableBooking switch is ON for the venue
  if (!props.enableNextAvailableBooking) return false;

  // Schedules must've been loaded
  if (props.scheduleLoadStatus !== loadStatus.success) return false;

  // Booking form must not be opened in edit mode
  if (props.isEditMode) return false;

  // Atleast 1 pax is selected
  if (numberOfPax < 1) return false;

  /**
   * When creating a booking, if the required verification switch is ON then,
   * the verification pop-up must be accepted
   */
  if (props.additionalBookingRequirements?.requiresVerification && !props.haveAcceptedVerification) return false;

  // Standby mode is OFF
  if (props.isStandbyMode) return false;

  // Tables and services are unavailable
  if (!props.noTablesAvailable && props.filteredServices?.length > 0) return false;

  return true;
}

