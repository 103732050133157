// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cvcPopover--9vTki{padding:10px 15px;width:305px;background-color:#fff}.cvcPopoverImg--F22Zg{width:275px;height:275px}.cvcPopoverText--8rNrs{font-size:1.1rem}.cvcPopoverTextIsDark--gt2xn{color:rgba(0,0,0,.87)}.cvcLink--Yr5mZ{position:absolute;right:0;bottom:6px;font-size:1rem;padding:3px 9px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cvcPopover": "cvcPopover--9vTki",
	"cvcPopoverImg": "cvcPopoverImg--F22Zg",
	"cvcPopoverText": "cvcPopoverText--8rNrs",
	"cvcPopoverTextIsDark": "cvcPopoverTextIsDark--gt2xn",
	"cvcLink": "cvcLink--Yr5mZ"
};
export default ___CSS_LOADER_EXPORT___;
