import React, {useEffect, useState} from 'react';
import style from './style.module.scss';
import {DatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import {MaterialUiPickersDate} from '@material-ui/pickers/typings/date';
import MomentUtils from '@date-io/moment';
import {IWidgetTheme, themeTypes, wrapperStyleType} from 'app/models';
import {IDispatchFromProps, IStateFromProps} from './types';
import {Button, Paper, Typography} from '@material-ui/core';
import classNames from 'classnames';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import {renderIf} from 'app/services/utils/utils.service';
import {IframeResizerService} from "app/services/iframeResizer/iframeResizer.service";
import {getDynamicStyles} from './style.dynamic';
import {MessageService} from "app/services/message/message.service";
import ReactMarkdown from "react-markdown";

const NS = 'DayPicker';


export default function DayPicker({
  theme, wrapperStyle, today, viewTime, maxDate, handleDayUpdate, isTimeViewShown, handleBack, orgDate,
  activeVenue
}: IStateFromProps & IDispatchFromProps) {

  const isWide = wrapperStyle === wrapperStyleType.wide;
  const isStacked = IframeResizerService.isStacked(wrapperStyle);
  const isNarrow = wrapperStyle === wrapperStyleType.narrow;
  const classes = getDynamicStyles(isNarrow);
  const [showTimeBtn, setShowTimeBtn] = useState<boolean>(false);
  const [date, setDate] = useState(today);
  const [newOrgDate, setOrgDate] = useState(orgDate);
  const [isCollapsed, setIsCollapsed] = useState(false);

  const isDateFarInAdvance = maxDate ? maxDate.isBefore(newOrgDate) : false;
  const tooFarInAdvanceMessage = MessageService.get('tooFarInAdvanceMessage', activeVenue);

  /** Update date picker when date is changed */
  useEffect(() => {
    // needed to disable collapse on stacked/mobile because it was happening on first load
    handleDateChange(today, false);
  }, [today]);

  const handleDateChange = (dateObj: MaterialUiPickersDate, doCollapseStacked = true): void => {
    if (doCollapseStacked && isStacked) {
      setIsCollapsed(true);
    }
    dateObj && setDate(dateObj);
  }
  const handleDateAccept = (dateObj: MaterialUiPickersDate): void => {
    setShowTimeBtn(false);
    setOrgDate(dateObj);
    handleDayUpdate(dateObj);
  }

  return (
    <div>
      <Paper className={classNames({
        [style.root]: true,
        [style.rootIsWide]: isWide,
        [style.rootIsCollapsable]: isStacked,
        [style.rootIsCollapsed]: isCollapsed
      })} elevation={1}>

        <div className={classNames({
          [style.customToolbar]: true,
          [classes.customToolbar]: true,
          [classes.customToolbarIsOutlinedLight]: theme.type === themeTypes.outlinedLight,
          [classes.customToolbarIsOutlinedDark]: theme.type === themeTypes.outlinedDark,
          [style.customToolbarIsWide]: isWide,
          [classes.customToolbarIsWide]: isWide,
          [classes.customToolbarIsOutlinedDarkLandscape]: isWide && theme.type === themeTypes.outlinedDark
        })}>
          {renderIf(isStacked, () => (
            <button type="button" className={style.toolbarBtn} onClick={() => setIsCollapsed(!isCollapsed)}></button>
          ))}
          <Typography variant="h4">
            {date.format('YYYY')}
          </Typography>
          <Typography variant="h6">
            {date.format('ddd, MMM DD')}
          </Typography>
          {viewTime ?
            <Typography variant="h6">
              {viewTime}
            </Typography>
            : null
          }
        </div>

        <div className={classNames({
          [style.calenderWrap]: true,
          // NBI-951 removed times from DayPicker
          // [style.calenderWrapIsHidden]: isTimeViewShown && isLandscape
        })}>
          <div className={classNames({
            [style.calender]: true,
            [style.calenderIsNarrow]: isNarrow,
            [classes.calender]: true,
            [style.calenderHasTimeBtn]: showTimeBtn
          })}>
            {renderIf(showTimeBtn, (
              <Button
                data-testid="times-btn"
                aria-label="view times"
                size="small"
                className={style.backBtn}
                onClick={() => handleBack(true)}>
                View times
                <ArrowForwardIcon fontSize="small" className={style.forwardArrow}/>
              </Button>
            ))}
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <DatePicker
                value={date}
                autoOk
                disablePast
                disableToolbar
                maxDate={maxDate}
                orientation={isWide ? 'landscape' : 'portrait'}
                variant="static"
                openTo="date"
                onChange={handleDateChange}
                onAccept={handleDateAccept}
                // onError={(err) => {
                //   console.warn(NS, 'error', err);
                // }}
              />
            </MuiPickersUtilsProvider>
          </div>
        </div>


        {/*
      // NBI-951 removed times from DayPicker
      <div className={classNames({
        [style.times]: true,
        [style.timesIsWide]: isWide,
        [style.timesIsHidden]: isLandscape ? !isTimeViewShown : true
      })}>
        <TimePickerContainer handleBack={() => {
          setShowTimeBtn(true);
          handleBack(false);
        }} />
      </div> */}
      </Paper>
      {renderIf(isDateFarInAdvance, (
        <div className={style.errorMsg}>
          <Typography component="span" variant="body2">
            <ReactMarkdown source={tooFarInAdvanceMessage} renderers={{paragraph: 'p'}} escapeHtml={false}/>
          </Typography>
        </div>
      ))}
    </div>

  )
}
