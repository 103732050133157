import React, { Component } from 'react';
import style from './style.module.scss';
import {StripeProvider, Elements, ReactStripeElements} from 'react-stripe-elements';
import { IPaymentDetailsStripe } from './types';
import PaymentCardStripe from '../PaymentCardStripe';
import { LocationService } from 'app/services/location/location.service';
import { IPaymentDetailsGenericData } from '../PaymentDetailsGeneric/types';
import { renderIf } from 'app/services/utils/utils.service';
import { Paper, CircularProgress } from '@material-ui/core';

const NS = 'PaymentDetailsStripe';

interface IState {
  fontFamilyUrlFriendly: string;
  protocol: string;
}

export default class PaymentDetailsStripe extends Component<IPaymentDetailsStripe & {children?: any}, IState> {
  
  readonly state: IState = {
    fontFamilyUrlFriendly: null,
    protocol: null
  }

  componentDidMount() {

    const stripeScript = document.head.querySelector('script[src^="https://js.stripe.com/v3"]');
    if (!stripeScript) {
      import('@stripe/stripe-js')
        .then(({loadStripe}) => {
          // console.log(NS, 'stripe loaded conditionally', loadStripe)
          
          if (!this.props.stripeInstance) {
            loadStripe(this.props.publishableKey)
              .then((stripe) => {
                this.props.handleStripeLoaded(stripe as unknown as  stripe.Stripe);
              });
          }
        });
    }

    if (this.props.stripeInstance) {
      const fontFamilies = this.props.theme.typography && this.props.theme.typography.fontFamily ? this.props.theme.typography.fontFamily : null;
      const fontFamily = fontFamilies ? fontFamilies.split(',')[0] : null;
  
      this.setState({
        fontFamilyUrlFriendly: fontFamily ? fontFamily.split(' ').join('+').split('"').join('') : '',
        protocol: 'http' + (LocationService.isSSH() ? 's' : '')
      });
    }
  }

  render() {
    const {
      stripeInstance, theme, triedNext, wrapperStyle,
      handleUpdate
    } = this.props;

    return (
      renderIf(stripeInstance && this.state.fontFamilyUrlFriendly && this.state.protocol, () => (
        <StripeProvider stripe={stripeInstance}>
          <Elements
            fonts={[
              {cssSrc: `${this.state.protocol}://fonts.googleapis.com/css?family=${this.state.fontFamilyUrlFriendly}`}
            ]} >
              
            <PaymentCardStripe 
              stripe={stripeInstance as ReactStripeElements.StripeProps} 
              theme={theme}
              triedNext={triedNext}
              wrapperStyle={wrapperStyle}
              handleUpdate={(token: stripe.Token, paymentDetails: IPaymentDetailsGenericData, isValid: boolean, card: stripe.elements.Element) => {
                handleUpdate(card, token, paymentDetails, isValid);
              }}>
              {this.props.children}
            </PaymentCardStripe>
          </Elements>
        </StripeProvider>
      ), () => (
        <Paper elevation={1} className={style.loaderPanel}>
          <CircularProgress size={20} />
        </Paper>
      ))
    )
  }
}