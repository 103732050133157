import {IChangedCustomerDetails} from "app/actions/booking/interfaces";
import {IWidgetModel} from "app/models";
import {IBooking} from "app/services/booking/booking.types";
import {BookingActionsTypes} from "app/actions/booking/bookingActionsTypes";

const NS = 'changedCustomerDetails';

export default {
  [BookingActionsTypes.CHANGED_CUSTOMER_DETAILS]: (state: IWidgetModel, action: IChangedCustomerDetails): IWidgetModel => {

    let booking: IBooking = state.booking;
    let standbyData = state.standbyData;

    if (standbyData) {
      standbyData = {
        ...standbyData,
        tags: action.payload.tags,
        customer: {
          ...state.standbyData.customer,
          ...action.payload.customerDetails
        },
        // country: action.payload.country,
      }
    } else {
      booking = {
        ...booking,
        tags: action.payload.tags,
        customer: {
          ...state.booking.customer,
          ...action.payload.customerDetails
        },
        // country: action.payload.country,
      };
    }

    return {
      ...state,
      booking,
      country: action.payload.country,
      standbyData,
      isCustomerDetailsValid: action.payload.isValid,
      phoneWithoutPrefix: action.payload.phoneWithoutPrefix
    }
  }
}
