import { Dispatch } from "redux";
import { IEwayPaymentSummary, IManageBookingFail, IProcessStripeFullPayment, IProcessStripePreauthPayment, ISavePreAuth } from './interfaces';
import { BookingActionsNS } from './bookingActions';
import { IFunctionPaymentSummaryResponse,
  ISavePreAuthData,
   ISavePreAuthResponse,
   IProcessEwayPaymentResponse,
   ICreditCardEncrypted,
    ISetupPreAuth3DResponseData,
    IFinalizePreAuth3DData,
    ISetupPreAuth3DData,
    ISetupPreAuth3DResponse,
    IFinalizePreAuth3DResponse} from "app/services/payment/payment.types";
import { PaymentService } from "app/services/payment/payment.service";
import {
  first,
  catchError,
  map,
  switchMap,
  concatMap,
  retryWhen,
  delay,
  take,
  mergeMap
} from 'rxjs/operators';
import {of, Observable, from, InteropObservable, throwError} from 'rxjs';
import { IErrorResponse, bookingErrorType } from "app/services/error/error.types";
import { IPayNowResponse, IPrepareEwayFunctionResponse, IEwayForm, ISchedule, IStripeInfo } from 'app/services/client/client.types';
import { IRootState } from "app/reducers";
import { IPaymentDetailsGenericData } from "app/components/PaymentDetailsGeneric/types";
import { IVenue, IWidgetModel } from "app/models";
import { ClientService } from "app/services/client/client.service";
import { ErrorService } from "app/services/error/error.service";
import {IActionGen, loadStatus} from "app/types/common.types";
import {
  ISavedBooking,
  IBookingMenuOption,
  IBooking
} from "app/services/booking/booking.types";
import { SetupActionsNS } from "../setup/setupActions";
import { SessionService } from "app/services/session/session.service";
import { appLoadCompleteSuccess } from "../setup/helpers";
import { IResponse } from "app/containers/App/types";
import {BookingActionsTypes} from "app/actions/booking/bookingActionsTypes";

const NS = 'BookingActions';

export function payNow(
  type: 'eway' | 'stripe',
  venueId: number,
  bookingId?: string,
  stripePaymentToken?: string,
  eventId?: string,
  ewayAccessCode?: string
): Observable<IFunctionPaymentSummaryResponse | IPayNowResponse | IPrepareEwayFunctionResponse> {

  if (eventId) { // this is only for private functions, not regular events
    if (type === 'eway') {
      return PaymentService.eventPaynow({venueId, eventId})
    }
    // stripe
    return getFunctionPaymentSummary(venueId, stripePaymentToken, eventId, ewayAccessCode);
  }
  return PaymentService.payNow({venueId, bookingId, stripePaymentToken}).pipe(first())
}


export function processStripePreAuth(
  state: IRootState,
  card: stripe.elements.Element,
  token: stripe.Token,
  paymentDetails: IPaymentDetailsGenericData
): Observable<IProcessStripePreauthPayment> {

  const { activeVenue} = state.widget;
  const stripe3DEnabled = (activeVenue.paymentSettings as IStripeInfo).stripe3DEnabled
  if(stripe3DEnabled){
    const {bookingId, venueId} = state.widget.appSettings;
    const {_id, payment} = state.widget.booking;   // SBL type bookings
    const {stripe} = state.widget;
    return proccessPreAuthStripe3D((bookingId ?? _id), venueId, stripe, card).pipe(
      map(data => ({
        successPayload: data,
        errorPreauthPayload: null
      })),
      catchError(err => {
          console.warn('Error during stripe3d preauth', err);
          return of({
            errorStripePayload: {
              stripeError: err,
              backEndError: null
            }
          })
        }
      ))
  }
  else {
    return processStripePreAuthNo3D(state, card, token, paymentDetails)
  }
}


export function processStripePreAuthNo3D(
  state: IRootState,
  card: stripe.elements.Element,
  token: stripe.Token,
  paymentDetails: IPaymentDetailsGenericData
): Observable<IProcessStripePreauthPayment> {

  return PaymentService.processStripePayment(state.widget.stripe, card, token, paymentDetails)
    .pipe(
      first(),
      switchMap((stripeResponse: stripe.PaymentMethodResponse) => {

        if (stripeResponse.error) {
          return of({
            errorStripePayload: {
              stripeError: stripeResponse.error,
              backEndError: null
            }
          });
        }

        return savePreAuth(state, null, {
          title: '',
          name: token.card.name,
          number: null,
          expiryMonth: token.card.exp_month.toString(),
          expiryYear: token.card.exp_year.toString(),
          cvn: null
        }, token.id).pipe(
          map(preAuthData => ({
            successPayload: preAuthData.successPayload,
            errorPreauthPayload: preAuthData.errorPayload
          }))
        );
      }),
      catchError((data: {response: IErrorResponse}) => {
        console.warn(NS, 'processStripePreAuth error', data);

        return of({
          errorStripePayload: {
            stripeError: null,
            backEndError: data.response
          }
        })
      })
    );
}

/**
 * Save eway preauth logic.
 */
export function savePreAuth(state: IRootState, ewayForm: IEwayForm, stripeData: ICreditCardEncrypted = null, stripePaymentToken: string = null): Observable<ISavePreAuth> {
  const widget = state.widget;
  const venue: IVenue = widget.activeVenue as IVenue;
  const {bookingId, venueId} = widget.appSettings;

  const clientSideEncryptKey = venue.clientSideEncryptKey;
  const params: ISavePreAuthData = {
    venueId,
    bookingId: bookingId || widget.booking._id,
    customerId: widget.booking.customer._id,
    creditCardDto: ewayForm ? ClientService.getEncryptedCardDetails(ewayForm, clientSideEncryptKey) : stripeData,
    preAuthReleasingWindow: venue.preAuthReleasingWindow || 24
  };

  if (stripePaymentToken) {
    params.stripePaymentToken = stripePaymentToken;
  }

  return PaymentService.savePreAuth(params).pipe(
    first(),
    map(({data}: ISavePreAuthResponse) => {
      return {
        successPayload: data
      }
    }),
    catchError((data: {response: IErrorResponse}) => {
      console.warn(NS, 'savePreAuth error', data);

      return of({
        errorPayload: ErrorService.getPaymentErrorTypeFromStatus(data.response.status)
      })
    })
  )
}


function proccessPreAuthStripe3D(
    bookingId: string,
    venueId: number,
    stripe: stripe.Stripe,
    card: stripe.elements.Element): Observable<any>{

      return setupPreAuthStripe3D(bookingId, venueId).pipe(
    switchMap((setupResponse: ISetupPreAuth3DResponse) => {
      return finalizePreAuthStripe3D(bookingId, venueId, setupResponse.data, stripe, card);
    })
  );
}

function setupPreAuthStripe3D(
  bookingId: string,
  venueId: number
): Observable<ISetupPreAuth3DResponse> {
  const setupPreAuthRequest: ISetupPreAuth3DData = {
    venueId: venueId,
    bookingId: bookingId
  }
  return PaymentService.setupPreAuthStripe3D(setupPreAuthRequest);
}

function finalizePreAuthStripe3D(
  bookingId: string,
  venueId: number,
  setupPreAuthResponse: ISetupPreAuth3DResponseData,
  stripe: stripe.Stripe,
  card: stripe.elements.Element
): Observable<IFinalizePreAuth3DResponse> {
  return from(stripe.confirmCardSetup(setupPreAuthResponse.clientSecret, { payment_method: { card: card } }))
    .pipe(
      switchMap((setupIntentResponse: stripe.SetupIntentResponse) => {
        if (setupIntentResponse.error) {
          throw setupIntentResponse.error;
        }
        const finalizeRequest: IFinalizePreAuth3DData = {
          venueId: venueId,
          bookingId: bookingId,
          tokenCustomerId: setupPreAuthResponse.tokenCustomerId,
          setupIntentId: setupPreAuthResponse.setupIntentId
        }

        return PaymentService.finalizePreAuth3D(finalizeRequest)
      }),
      // If error is found then we retry
      retryWhen((errors) => {
        return errors.pipe(
          delay(1000),
          take(2), // Number of retries
          concatMap((errors, index) => {
            console.log('error found retry...', index);
            return index === 1 ? throwError(errors) : of(null)
          }),
        )
      }),
    );
}

interface IStripePaidData {transactionId: string, amountPaid: number}

/**
 * Payment logic for either Stripe 3D Secure or standard Stripe payment
 * stripePayment$: either `PaymentService.paymentIntent3DSecure` or `PaymentService.processStripePayment`
 * backendPayment$: either `PaymentService.finilisePayment3DSecure` or `payNow`
 */
function makeStripePayment(
  stripePayment$: Observable<stripe.PaymentMethodResponse>,
  successPaidCallback: (paymentIntent: stripe.paymentIntents.PaymentIntent) => Observable<{data: IStripePaidData}>
 ): Observable<IProcessStripeFullPayment> {

  return stripePayment$
    .pipe(
      switchMap((stripeResponse: stripe.PaymentMethodResponse | stripe.PaymentIntentResponse) => {

        return (stripeResponse.error
          ? of(null)
          : successPaidCallback((stripeResponse as stripe.PaymentIntentResponse).paymentIntent || null) // paymentIntent will be null for non 3d secure payments
         )
          .pipe(
            // tap(response => console.log(NS, 'processStripePayment', response)),
            map((response: IResponse<IStripePaidData>) => ({
              backendPayData: response ? response.data : null,
              stripeResponse
            })),
            catchError(error => {
              console.warn(NS, 'paynow error', error)

              return of({
                backendPayData: {error},
                stripeResponse
              });
            })
          );
      }),
      map(({backendPayData, stripeResponse}) => {
        const hasPayNowError = !!(backendPayData && (backendPayData as {error: any}).error);
        if (stripeResponse.error || hasPayNowError) {
          return {
            errorPayload: {
              stripeError: hasPayNowError ? null : stripeResponse.error,
              backEndError: hasPayNowError ? (backendPayData as {error: any}).error : null
            }
          }
        }

        /**
         * SUCCESS
         */
        const {transactionId, amountPaid} = backendPayData as IStripePaidData;
        return {
          successPayload: {
            transactionId,
            amountPaid,
            response: stripeResponse
          }
        }
      }),
      catchError((data: {response: IErrorResponse}) => {
        console.warn(NS, 'processStripePayment error', data);

        return of({
          errorPayload: {
            stripeError: null,
            backEndError: data.response
          }
        })
      })
    );
}

export function processStripe3DSecurePayment(
  state: IRootState,
  stripe: stripe.Stripe,
  card: stripe.elements.Element,
  bookingId: string,
  venueId: number,
  amountDue: number,
  fee: number,
  booking : IBooking
): Observable<any> { // IProcessStripeFullPayment
  const { appSettings } = state.widget;

  const processPayment$: Observable<IResponse<string>> = appSettings.privateFunction
    ? PaymentService.paymentIntent3DSecureForPF(appSettings.eventId, venueId, booking, fee || 0)
    : PaymentService.paymentIntent3DSecure(bookingId, venueId, amountDue, booking, fee || 0);

  return processPayment$
    .pipe(
      first(),
      switchMap(({data}: IResponse<string>) => makeStripePayment(
        // stripePayment$
        from(stripe.confirmCardPayment(data, {payment_method: {card}})),

        // successPaidCallback
        (paymentIntent: stripe.paymentIntents.PaymentIntent) => {
          const amountAs2Decimals = paymentIntent ? paymentIntent.amount / 100 : null;

          const finalizePayment$: Observable<IResponse<any>> = appSettings.privateFunction
            ? PaymentService.finalisePayment3DSecureForPF(appSettings.eventId, venueId, paymentIntent.id, amountAs2Decimals)
            : PaymentService.finilisePayment3DSecure(bookingId, venueId, paymentIntent.id, amountAs2Decimals);

          return finalizePayment$;
        })
      ))
}

export function processStripePayment(
  state: IRootState,
  card: stripe.elements.Element,
  token: stripe.Token,
  paymentDetails: IPaymentDetailsGenericData
): Observable<IProcessStripeFullPayment> {
  const {stripe, activeVenue, appSettings} = state.widget;
  const bookingId = appSettings.bookingId || state.widget.booking._id;

  return makeStripePayment(
    // stripePayment$
    PaymentService.processStripePayment(stripe, card, token, paymentDetails),

    // successPaidCallback
    () => payNow('stripe', (activeVenue as IVenue).id, bookingId, token.id, appSettings.eventId).pipe(first())
  );
}

/**
 * processes eway payment and retrieves the payment summary info
 */
export function processEwayPayment(state: IRootState, formEl: HTMLFormElement): Observable<IEwayPaymentSummary> {
  const widget = state.widget;
  const {venueId, eventId} = widget.appSettings;
  const bookingId = widget.appSettings.bookingId || widget.booking._id;

  return PaymentService.processEwayPayment(formEl)
    .pipe(
      first(),
      map(successData => ({successData})),
      catchError((errorType: bookingErrorType) => {
        console.warn(NS, 'submitEwayPayment error', errorType);

        return of({
          errorDispatchData: {type: BookingActionsTypes.BOOKING_ERROR, payload: errorType}
        });
      }),

      switchMap((response: IEwayPaymentSummary) => {
        if (response.errorDispatchData) {
          return of({errorDispatchData: response.errorDispatchData});
        }
        const data: IProcessEwayPaymentResponse = response.successData as IProcessEwayPaymentResponse;
        return getEwayPaymentSummary(venueId, bookingId, eventId, data.AccessCode);
      })
    );
}

export function loadScheduleForSavedBooking(
  dispatch: Dispatch, activeVenue: IVenue, savedBooking: ISavedBooking
): Promise<ISchedule> {

  return new Promise((resolve, reject) => {

    dispatch({type: BookingActionsTypes.SERVICE_SCHEDULE_LOADING});

    dispatch({type: SetupActionsNS.Type.APP_LOAD_COMPLETE, payload: {
      completeLoadStatus: false,
      status: loadStatus.loading
    }} as SetupActionsNS.IAppLoadComplete);

    // on back end, when providing bookingId, it will remove the booking from the bookings list, so that it can become available again
    const bookingId: string = savedBooking.bookingId;

    ClientService.getSchedule(savedBooking.moment, savedBooking.covers, (activeVenue as IVenue).id, bookingId)
      .pipe(first())
      .subscribe((value: ISchedule) => {
        dispatch({type: BookingActionsTypes.SERVICE_SCHEDULE_SUCCESS, payload: value} as IActionGen<ISchedule>);
        appLoadCompleteSuccess(dispatch);
        resolve(value);
      }, ({response}: {response: IErrorResponse}) => {
        console.warn(NS, 'loadScheduleForSavedBooking', 'error', response)
        // dispatch({type: BookingActionsTypes.SERVICE_SCHEDULE_FAILED} as IAction);
        reject(response);
      });
  });
}

export function bookingNotFoundDispatch(dispatch: Dispatch): void {
  dispatch({type: BookingActionsTypes.EDIT_BOOKING_FAIL, payload: {
    title: 'Link Expired',
    message: 'Please contact us on <a class="underline-phone" href="tel:{{phone}}">{{phone}}</a>.'
  }} as IManageBookingFail);
  appLoadCompleteSuccess(dispatch);
}

function getEwayPaymentSummary(
  venueId: number,
  bookingId?: string,
  eventId?: string,
  ewayAccessCode?: string
): Observable<IEwayPaymentSummary> {

  if (eventId) {
    return getFunctionPaymentSummary(venueId, null, eventId, ewayAccessCode)
      .pipe(
        first(),
        map(successData => ({successData})),
        catchError((response: IErrorResponse) => {
          console.warn(NS, 'getFunctionPaymentSummary error', response);

          return of({
            errorDispatchData: {type: BookingActionsTypes.PAYMENT_SUMMARY_FAIL, payload: response}
          });
        })
      )
  }

  return PaymentService.getPaymentSummary(bookingId, venueId, ewayAccessCode)
    .pipe(
      first(),
      map(successData => ({successData})),
      catchError((response: IErrorResponse) => {
        console.warn(NS, 'getPaymentSummary error', response);

        return of({
          errorDispatchData: {type: BookingActionsTypes.PAYMENT_SUMMARY_FAIL, payload: response}
        });
      })
    )
}

export function finishSession(dispatch: Dispatch): void {
  dispatch({type: BookingActionsTypes.SESSION_FINISHED});
  SessionService.stopSession(false);
}

function getFunctionPaymentSummary(
  venueId: number,
  stripePaymentToken?: string,
  eventId?: string,
  ewayAccessCode?: string
): Observable<IFunctionPaymentSummaryResponse> {
  return PaymentService.getFunctionPaymentSummary(venueId, eventId, ewayAccessCode, stripePaymentToken)
    .pipe(
      first(),
      catchError(err => {
        // @todo handle error. severity: high
        // $log.error('Confirming booking failed', err);
        // errorService.processPaymentError(err);
        return of({
          status: err.status,
          data: null
        })
      })
    );
}

/**
 * returns flat list of ids for all menu options and child menu options
 */
export function getMenuOptionIds(selectedMenuOptions: IBookingMenuOption[]): string[] {
  return selectedMenuOptions.reduce((acc, {menuOptionId, extras}) => {
    acc.push(menuOptionId);
    if (extras) {
      if (extras.explicitChildMenuOptions) {
        extras.explicitChildMenuOptions.forEach(opts => {
          opts.forEach(o => acc.push(o.menuOptionId));
        });
      }
      if (extras.implicitChildMenuOptions) {
        extras.implicitChildMenuOptions.forEach(o => acc.push(o.menuOptionId));
      }
    }
    return acc;
  }, []);
}


export function hideUpsellPopup(dispatch: Dispatch, hideUpsell = false): void {
  dispatch({
    type: BookingActionsTypes.HIDE_UPSELL_POPUP, payload: {
      hideUpsell
    }
  } as IActionGen<{ hideUpsell: boolean }>);
}
