// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".root--sVRqZ{display:flex;justify-content:space-between}.nextBtn--8DIkq{margin-left:8px}.navBtn--5srag{margin-left:8px}.navBtn--5srag:first-child{margin-left:0}.navBtn--5srag,.nextBtn--8DIkq{line-height:1.3;padding:8px 15px;font-size:1.5rem}.navBtnIsChunky--XlacM,.nextBtnIsChunky--aIUS0{font-size:1.6rem}.disabledNext--jSokZ{opacity:0;position:absolute;box-shadow:none;right:0}.nextWrap--AiRT\\+{position:relative}.navBtnIsTightFit--rckyp,.nextBtnIsTightFit--p1Yy9{font-size:1.4rem;padding-right:9px;padding-left:9px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "root--sVRqZ",
	"nextBtn": "nextBtn--8DIkq",
	"navBtn": "navBtn--5srag",
	"navBtnIsChunky": "navBtnIsChunky--XlacM",
	"nextBtnIsChunky": "nextBtnIsChunky--aIUS0",
	"disabledNext": "disabledNext--jSokZ",
	"nextWrap": "nextWrap--AiRT+",
	"navBtnIsTightFit": "navBtnIsTightFit--rckyp",
	"nextBtnIsTightFit": "nextBtnIsTightFit--p1Yy9"
};
export default ___CSS_LOADER_EXPORT___;
