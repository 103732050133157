import {IVenue, IWidgetModel} from "app/models";
import {IBookingMenuOption, IBookingMenuOptionExtrasUpdater} from "app/services/booking/booking.types";
import {Observable, of} from "rxjs";
import {IMenuOptionsResponse, IPaymentType} from "app/services/client/client.types";
import {MenuOptionsService} from "app/services/menuOptions/menuOptions.service";
import {ClientService} from "app/services/client/client.service";
import {first, map} from "rxjs/operators";
import {IResponse} from "app/containers/App/types";
import {IParentMenuOption} from "shared-components/booking-options-section/ChildMenuOptionOverlay/types";
import {Dispatch} from "redux";
import {IRootState} from "app/reducers";
import {IActionGen} from "app/types/common.types";
import {
  ISelectedMenuOption,
  IUpdateActiveChildMenuOptionPayload,
  IUpdateBookingOptionPaymentType,
  IUpdateCachedChildMenuOptionDetails
} from "app/actions/booking/interfaces";
import {sendBookingOptionAnalytics} from "app/actions/booking/analyticsHelpers";

function getPaymentTypesPerBookingOption(widget: IWidgetModel, {
  parentId, isSameForAll, explicitChildMenuOptions, implicitChildMenuOptions
}: IBookingMenuOptionExtrasUpdater): Observable<IPaymentType> {

  const { activeVenue, activeService, booking, filteredTimes, activeChildMenuOption } = widget;
  const parentBookingOption = booking.selectedMenuOptions.find(({menuOptionId}) => menuOptionId === parentId);
  if (parentBookingOption && parentBookingOption.quantity) {

    const utcTime = booking.utcTime || filteredTimes[0].time; // if no time is selected, then we just take the first available time

    const {quantity, menuOptionId} = parentBookingOption;
    const bookingOption: IBookingMenuOption = {
      quantity, menuOptionId,
      extras: {
        isSameForAll, explicitChildMenuOptions, implicitChildMenuOptions
      }
    };

    const flattened = MenuOptionsService.getFlatExtras([bookingOption]);
    // console.log(NS, 'getPaymentTypesPerBookingOption', flattened, bookingOption)
    return ClientService.getPaymentType((activeVenue as IVenue).id, flattened, activeService.id, utcTime, booking.covers, true)
      .pipe(
        first(),
        map(({data}: IResponse<IPaymentType>) => data)
      );
  }
  return of(null);
}


export namespace BookingOptionsActionsNS {

  // action type
  export enum Type {
    UPDATE_MENU_OPTION_EXTRAS = 'UPDATE_MENU_OPTION_EXTRAS',
    UPDATE_BOOKING_OPTION_PAYMENT_TYPE = 'UPDATE_BOOKING_OPTION_PAYMENT_TYPE',
    UPDATE_ACTIVE_CHILD_MENU_OPTION = 'UPDATE_ACTIVE_CHILD_MENU_OPTION',
    UPDATE_CACHED_MENU_OPTION_DETAILS = 'UPDATE_CACHED_MENU_OPTION_DETAILS',
    SELECTED_MENU_OPTION = 'SELECTED_MENU_OPTION',
    UPDATE_SELECTED_MENU_OPTION = 'UPDATE_SELECTED_MENU_OPTION',
  }


  export const selectedMenuOptions = (menuOptions: IBookingMenuOption[], isUpsell = false) => (dispatch: Dispatch, getState: () => any): Promise<void> => {
    return new Promise(resolve => {
      const {widget} = getState() as IRootState;
      const {activeVenue} = widget;

      if (!isUpsell) {
        sendBookingOptionAnalytics(activeVenue, menuOptions);

        dispatch({type: Type.SELECTED_MENU_OPTION, payload: menuOptions} as ISelectedMenuOption);
        resolve();
      } else {
        dispatch({type: Type.UPDATE_SELECTED_MENU_OPTION, payload: menuOptions} as ISelectedMenuOption);
        resolve();
      }


    })
  }

  export const updateActiveChildMenuOption = (parentMenuOption: IParentMenuOption) => (dispatch: Dispatch, getState: () => any): Promise<void> => {
    return new Promise(resolve => {
      const {widget} = getState() as IRootState;
      const {activeVenue, cachedMenuOptionDetails} = widget;

      const implicitIds = parentMenuOption && parentMenuOption.childMenuOptionIds ? parentMenuOption.childMenuOptionIds : [];
      const explicitIds = parentMenuOption && parentMenuOption.explicitChildMenuOptionIds ? parentMenuOption.explicitChildMenuOptionIds : [];

      if (!implicitIds.length && !explicitIds.length) {
        dispatch({type: Type.UPDATE_ACTIVE_CHILD_MENU_OPTION, payload: null} as IActionGen<IUpdateActiveChildMenuOptionPayload>);
        resolve();
        return;
      }

      const ids: string[] = implicitIds.concat(explicitIds);

      // if all details are already cached there is no need to get them again, so we bypass the request and use the cache
      const allAreCached: boolean = ids.every(id => cachedMenuOptionDetails.find(o => o.id === id));
      if (allAreCached) {
        dispatch({type: Type.UPDATE_ACTIVE_CHILD_MENU_OPTION, payload: {
            implicitChildMenuOptions: implicitIds.map(id => cachedMenuOptionDetails.find(o => o.id === id)),
            explicitChildMenuOptions: explicitIds.map(id => cachedMenuOptionDetails.find(o => o.id === id))
          }} as IActionGen<IUpdateActiveChildMenuOptionPayload>);
        resolve();
        return;
      }

      ClientService.getBookingOptions(ids.join(','), (activeVenue as IVenue).id)
        .pipe(
          first()
        ).subscribe((response: IMenuOptionsResponse) => {
        if (response) {
          dispatch({type: Type.UPDATE_ACTIVE_CHILD_MENU_OPTION, payload: {
              implicitChildMenuOptions: response.data.splice(0, implicitIds.length), // extracts the implicit list
              explicitChildMenuOptions: response.data // explicit is whatever is left
            }} as IActionGen<IUpdateActiveChildMenuOptionPayload>);
        }
        resolve();
      }, err => {
        // @todo dispatch empty update and some kind of error UI
        resolve();
      });
    });
  }


  export const updateCachedMenuOptionDetails = (ids: string[], fullRefresh = false) => (dispatch: Dispatch, getState: () => any): Promise<void> => {
    return new Promise((resolve, reject) => {
      const state = getState() as IRootState;
      const {activeVenue} = state.widget;

      ClientService.getBookingOptions(ids.join(','), (activeVenue as IVenue).id)
        .pipe(first())
        .subscribe((response: IMenuOptionsResponse) => {
          if (response) {
            dispatch({type: Type.UPDATE_CACHED_MENU_OPTION_DETAILS, payload: { options: response.data, fullRefresh }} as IUpdateCachedChildMenuOptionDetails);
          }
          resolve();
        }, err => {
          reject();
        });
    });
  }


  export const updateMenuOptionExtras = (data: IBookingMenuOptionExtrasUpdater) => (dispatch: Dispatch, getState: () => any): Promise<void> => {
    return new Promise(resolve => {
      const {widget} = getState() as IRootState;

      /**
       * @param {[type]}
       */
      if (data.parentQuantity > 0) {
        getPaymentTypesPerBookingOption(widget, data)
          .pipe(first())
          .subscribe(({paymentTypeName, amount}: IPaymentType) => {
            const payload: IUpdateBookingOptionPaymentType = {
              paymentTypeOverride: { paymentType: paymentTypeName, amount, hasError: false},
              id: data.parentId
            };
            dispatch({type: Type.UPDATE_BOOKING_OPTION_PAYMENT_TYPE, payload} as IActionGen<IUpdateBookingOptionPaymentType>);
          }, err => {
            const payload: IUpdateBookingOptionPaymentType = {
              paymentTypeOverride: { paymentType: null, amount: null, hasError: true },
              id: data.parentId
            };
            dispatch({type: Type.UPDATE_BOOKING_OPTION_PAYMENT_TYPE, payload} as IActionGen<IUpdateBookingOptionPaymentType>);
          });
      }

      dispatch({type: Type.UPDATE_MENU_OPTION_EXTRAS, payload: data} as IActionGen<IBookingMenuOptionExtrasUpdater>);
      resolve();
    });
  }

}
