import React from 'react';
import ReactMarkdown from 'react-markdown';
import style from './style.module.scss';
import { IStateFromProps, IDispatchFromProps } from './types';
import { Typography } from '@material-ui/core';
import classNames from 'classnames';
import { UtilsService, renderIf } from 'app/services/utils/utils.service';
import {IframeResizerService} from "app/services/iframeResizer/iframeResizer.service";
import { PaymentService } from 'app/services/payment/payment.service';
import {PricingTypes, servicePaymentType} from "shared-types/index";

const NS = 'ServiceMessage';

export default function ServiceMessage({
  activeService, hasTimes, hasMenuOptions, hasPayment, paymentType, pricingType, price, currency, wrapperStyle, bgColor, isNabActive
}: IStateFromProps & IDispatchFromProps) {
  const isPreAuth = paymentType === servicePaymentType.preAuth;
  const isStacked = IframeResizerService.isStacked(wrapperStyle);
  const isPricePerPerson = pricingType == PricingTypes.PerPax;
  return (
    <>
      {hasTimes ? // renders nothing unless service has times available
        <div className={classNames({
          [style.root]: true,
          [style.rootIsCenetered]: isStacked
        })}>
          {renderIf(activeService.description && !isNabActive, () => (
            <div className={classNames({
              [style.text]: true,
              [style.markdownText]: true,
            })}>
              <ReactMarkdown source={activeService.description}
                renderers={{
                  link: UtilsService.reactMarkDownBlankTargets
                }}
                escapeHtml={false} />
            </div>
          ))}

          {hasPayment && price > 0 && !hasMenuOptions && !isNabActive ?
            <Typography className={style.text}>
              {PaymentService.getPaymentMessage(paymentType, currency, price, isPricePerPerson)}
            </Typography>
            : null
          }
        </div>
        : null
      }
    </>
  )
}

