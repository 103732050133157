// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".root--WYsxU input:-webkit-autofill,.root--WYsxU input:-webkit-autofill:hover,.root--WYsxU input:-webkit-autofill:focus,.root--WYsxU input:-webkit-autofill:active{transition-delay:9999s}.column2--OptaU{height:100%;display:flex;flex-direction:column;justify-content:space-between}.paperBox--k\\+UOi{padding:15px 28px 34px;display:flex;flex-direction:column;justify-content:space-between}.paperBox1--67ADU{height:auto}.paperBox1IsLandscape--rOKTF{height:100%;max-height:360px}.paperBox2--gz4Z4{margin-bottom:15px;flex-grow:1}.formText--ZZQCl{width:100%;margin-bottom:5px}.formTextUseEnlargedFont--Ck8-p textarea,.formTextUseEnlargedFont--Ck8-p input{font-size:1.6rem !important}.terms--Nan7P{margin-bottom:20px}.terms--Nan7P textarea{font-size:1.2rem}.gridItem1--2gZNm,.gridItem3--NG4gu{display:flex;flex-direction:column;justify-content:space-between}.centerAlignItemContents--hLlUc{align-items:center}.termsControl--t81IX{margin-bottom:-10px}.tagsWrap--lz09T{padding:6px 4px}.tagsWrapIsLandscape--tbaSt{margin-left:-11px}.termsControl--t81IX{margin-right:25px}.tagControl--k-eDG{margin-bottom:3px;min-width:50%;margin-right:0;margin-left:0}.tagControlIsStacked--jwtYE{margin-left:0}.tagControlIsStandard--4hNYc{width:100%}.specialWrap--Fleg1{width:100%;margin-bottom:3px}.checkbox--PgrMl{padding-right:7px}.divider--q7aA\\+{margin-bottom:5px;background-color:rgba(0,0,0,.42)}.specialDivider2--BDyEL{margin-bottom:11px}.alert---z-7g{margin-top:18px;margin-bottom:-10px}.smallLabel--Whe86{font-size:1.2rem}.tcLink--PiA7m{text-align:center;margin-top:10px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "root--WYsxU",
	"column2": "column2--OptaU",
	"paperBox": "paperBox--k+UOi",
	"paperBox1": "paperBox1--67ADU",
	"paperBox1IsLandscape": "paperBox1IsLandscape--rOKTF",
	"paperBox2": "paperBox2--gz4Z4",
	"formText": "formText--ZZQCl",
	"formTextUseEnlargedFont": "formTextUseEnlargedFont--Ck8-p",
	"terms": "terms--Nan7P",
	"gridItem1": "gridItem1--2gZNm",
	"gridItem3": "gridItem3--NG4gu",
	"centerAlignItemContents": "centerAlignItemContents--hLlUc",
	"termsControl": "termsControl--t81IX",
	"tagsWrap": "tagsWrap--lz09T",
	"tagsWrapIsLandscape": "tagsWrapIsLandscape--tbaSt",
	"tagControl": "tagControl--k-eDG",
	"tagControlIsStacked": "tagControlIsStacked--jwtYE",
	"tagControlIsStandard": "tagControlIsStandard--4hNYc",
	"specialWrap": "specialWrap--Fleg1",
	"checkbox": "checkbox--PgrMl",
	"divider": "divider--q7aA+",
	"specialDivider2": "specialDivider2--BDyEL",
	"alert": "alert---z-7g",
	"smallLabel": "smallLabel--Whe86",
	"tcLink": "tcLink--PiA7m"
};
export default ___CSS_LOADER_EXPORT___;
