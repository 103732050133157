import React, { useState } from 'react';
import styles from './style.module.scss';
import ReactMarkdown from "react-markdown";
import { makeStyles } from '@material-ui/styles';
import { IVerificationPanelProps } from './types';
import { Button, Checkbox, FormControlLabel } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    '& .MuiFormControlLabel-label': {
      lineHeight: '1.2',
      fontSize: '1.5rem'
    },
    '& .MuiButton-contained': {
      color: '#000000ba',
      backgroundColor: '#009e1096'
    },
    '& .MuiButton-contained.Mui-disabled': {
      color: 'rgba(0, 0, 0, 0.26)',
      backgroundColor: 'rgba(0, 0, 0, 0.12)',
      boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)'
    },
    '& .MuiFormControlLabel-labelPlacementStart': {
      marginLeft: '0'
    },
    '& .MuiCheckbox-colorPrimary.Mui-checked': {
      color: '#009e1096'
    }
  }
});

/** Display the verification message which is needed to be accepted */
export const VerificationPanel = (props: IVerificationPanelProps) => {

  const classes = useStyles();
  const [haveAgreedToProvideProofOnArrival, setAgreementToProvideProofOnArrival] = useState<boolean>(false);

  return (
    <div  className={classes.root}>
      <ReactMarkdown className={styles.verificationMessage} source={props.verificationMessage} renderers={{ paragraph: 'span'}} escapeHtml={false} />
      <FormControlLabel
        value={true}
        labelPlacement="start"
        label="Yes - I agree and confirm for all guests"
        control= {
          <Checkbox
            color="primary"
            checked={haveAgreedToProvideProofOnArrival}
            onChange={() => setAgreementToProvideProofOnArrival(!haveAgreedToProvideProofOnArrival)}
          />
        }
      />
      <div className={styles.confirmBtnContainer}>
        <Button variant="contained" disabled={!haveAgreedToProvideProofOnArrival} onClick={() => props.onVerificationAcceptance()}>Agree and continue with booking</Button>
      </div>
    </div>
  )
}