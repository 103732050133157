// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".root--iqzIU{padding:12px 23px 17px;margin-bottom:15px}.rootUseEnlargedFont--GdiwL input{font-size:1.6rem !important}.cvcAndExpiryWrap--wzjJC{display:flex}.cvcAndExpiryWrapIsTightFit--jVV7q{display:block}.expiryWrap--lWLtC{display:flex;align-items:flex-end}.expirySlash--l30Bs{padding:0 15px}.expiryYear--ZInxG,.expiryMonth--H81BM{width:50px}.expiryYearIsTightFit--96QHr,.expiryMonthIsTightFit--9bRYK{width:100%}.cvc--GRpKd{width:150px}.cvcIsTightFit--1-vFd{width:100%}.cvcWrap--tFB5x{position:relative;flex-grow:1;display:flex;justify-content:flex-end}.formRow--8zPmT{margin-bottom:7px}.formRow--8zPmT input:-webkit-autofill,.formRow--8zPmT input:-webkit-autofill:hover,.formRow--8zPmT input:-webkit-autofill:focus,.formRow--8zPmT input:-webkit-autofill:active{transition-delay:9999s}.formRowIsDark--MlFDd input{color:#fff}.formRowIsDark--MlFDd input::-webkit-input-placeholder{color:rgba(255,255,255,.4)}.formRowIsDark--MlFDd input::-moz-placeholder{color:rgba(255,255,255,.4)}.formRowIsDark--MlFDd input:-ms-input-placeholder{color:rgba(255,255,255,.4)}.formRowIsDark--MlFDd input::-ms-input-placeholder{color:rgba(255,255,255,.4)}.formRowIsDark--MlFDd input::placeholder{color:rgba(255,255,255,.4)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "root--iqzIU",
	"rootUseEnlargedFont": "rootUseEnlargedFont--GdiwL",
	"cvcAndExpiryWrap": "cvcAndExpiryWrap--wzjJC",
	"cvcAndExpiryWrapIsTightFit": "cvcAndExpiryWrapIsTightFit--jVV7q",
	"expiryWrap": "expiryWrap--lWLtC",
	"expirySlash": "expirySlash--l30Bs",
	"expiryYear": "expiryYear--ZInxG",
	"expiryMonth": "expiryMonth--H81BM",
	"expiryYearIsTightFit": "expiryYearIsTightFit--96QHr",
	"expiryMonthIsTightFit": "expiryMonthIsTightFit--9bRYK",
	"cvc": "cvc--GRpKd",
	"cvcIsTightFit": "cvcIsTightFit--1-vFd",
	"cvcWrap": "cvcWrap--tFB5x",
	"formRow": "formRow--8zPmT",
	"formRowIsDark": "formRowIsDark--MlFDd"
};
export default ___CSS_LOADER_EXPORT___;
